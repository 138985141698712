import {
  Card,
  GeneralInformationFormFields,
  Spinner,
} from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { GetEmployeeDocument } from '@/shared/graphql';
import {
  useEmployeeAccess,
  useUpdateEmployeeGeneralInformation,
} from '@/shared/hooks';
import { apolloClient } from '@/shared/providers';
import {
  EmployeeGeneralInformationFormData,
  EmployeeGeneralInformationFormDataType,
  FormAction,
} from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CurrentContract } from './CurrentContract';
import { CurrentPosition } from './CurrentPosition';
import { DirectReports } from './DirectReports';
import { generalInformationFormValidator } from './general-information-form.validator';
import { GeneralInformationDetails } from './GeneralInformationDetails';

export const GeneralInformation = ({
  onFormStateChange,
}: {
  onFormStateChange: (isDirty: boolean) => void;
}) => {
  const {
    hasGeneralInformationEditAccess,
    loading,
    hasJobContractHistoryViewAccess,
  } = useEmployeeAccess();

  const { employeeDetail: employee } = useEmployeeDetailContext();
  const form = useForm<EmployeeGeneralInformationFormDataType>({
    defaultValues: EmployeeGeneralInformationFormData.parse({
      ...employee.generalInformation,
      preferredFullName: employee.generalInformation.preferredFullName,
      employeeCode: employee.generalInformation.employeeId,
    }),
    resolver: vestResolver(generalInformationFormValidator),
  });

  const { handleUpdateEmployeeGeneralInformation, loading: updating } =
    useUpdateEmployeeGeneralInformation({
      onCompleted: () => {
        form.reset(
          { ...form.getValues() },
          {
            keepValues: false,
            keepDirty: false,
            keepDefaultValues: false,
          }
        );
        apolloClient.refetchQueries({
          include: [GetEmployeeDocument],
        });
      },
    });

  useEffect(() => {
    onFormStateChange(form.formState.isDirty);
  }, [form.formState.isDirty, onFormStateChange]);

  useEffect(() => {
    form.reset({
      ...employee.generalInformation,
      preferredFullName: employee.generalInformation.preferredFullName,
      employeeCode: employee.generalInformation.employeeId,
    });
  }, [employee, form]);

  if (loading) {
    return <Spinner className='my-52' />;
  }

  return (
    <Card withPadding={false}>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={form.handleSubmit((formData) => {
            handleUpdateEmployeeGeneralInformation(formData, employee.id);
          })}
        >
          <div className='p-6'>
            {hasGeneralInformationEditAccess ? (
              <GeneralInformationFormFields mode={FormAction.EDITING} />
            ) : (
              <GeneralInformationDetails employee={employee} />
            )}

            <CurrentContract
              employee={employee}
              hasJobContractHistoryViewAccess={hasJobContractHistoryViewAccess}
            />
            <CurrentPosition
              employee={employee}
              hasJobContractHistoryViewAccess={hasJobContractHistoryViewAccess}
            />
            <DirectReports
              directReports={employee.generalInformation.directReports}
            />
          </div>
          <hr />
          {hasGeneralInformationEditAccess && (
            <div className='flex px-6 pb-6 pt-4'>
              <Button
                className='ml-auto bg-blue-6'
                type='submit'
                loading={updating}
              >
                Save
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </Card>
  );
};
