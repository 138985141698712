import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import {
  EmployeeCompanyAssetType,
  EmployeePersonalInformationFormDataType,
} from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { useFormContext } from 'react-hook-form';
import { CompanyAssetModal } from './CompanyAssetModal';
import { CompanyAssetTable } from './CompanyAssetTable';

interface CompanyAssetProps {
  onCreate: (data: EmployeeCompanyAssetType) => void;
  onUpdate: (data: EmployeeCompanyAssetType) => void;
  onRemove: (id: string) => void;
}

export const CompanyAsset = ({
  onCreate,
  onUpdate,
  onRemove,
}: CompanyAssetProps) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<EmployeePersonalInformationFormDataType>();

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const [companyAssets] = watch(['companyAsset']);
  const { hasPersonalInformationEditAccess } = useEmployeeAccess();
  const isEditable = hasPersonalInformationEditAccess || isNewRecord;

  return (
    <div className='mb-6'>
      <div className='mb-4 flex flex-row'>
        <h1 className='self-center'>Company Asset</h1>
        {isEditable && (
          <CompanyAssetModal
            className='ml-auto'
            onSuccess={onCreate}
            isNewRecord={true}
          >
            <Button variant='default'>Add Company Asset</Button>
          </CompanyAssetModal>
        )}
      </div>
      <CompanyAssetTable
        items={companyAssets}
        loading={false}
        onUpdate={onUpdate}
        onRemove={onRemove}
        isEditable={isEditable}
      />
      {errors.companyAsset && (
        <span className='text-xs text-red-6'>
          {errors.companyAsset.message}
        </span>
      )}
    </div>
  );
};
