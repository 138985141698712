import {
  BusinessUnitLazySelect,
  CompanyLazySelect,
  DEFAULT_PAGE_SIZE,
  EmployeeLazySelect,
  InputSearch,
  JobHistoryLevelLabels,
  JobLevelSelect,
} from '@/shared/components';
import { FilterProps } from '@/shared/hooks';
import { ProbationReviewFilterType } from '@/shared/types';

interface Props {
  filters: FilterProps<ProbationReviewFilterType>;
}

export const ProbationReviewFilters = ({ filters }: Props) => {
  const { changeFilter, removeFilter, current } = filters;

  return (
    <div className='grid grid-cols-8 gap-x-2 gap-y-4 p-4'>
      <InputSearch
        className='col-span-6'
        onClear={() => removeFilter('keyword')}
        onSearch={(text: string) => changeFilter('keyword', text)}
        placeholder={'Keyword search'}
        value={current.keyword}
      />

      <div className='col-span-2'>
        <CompanyLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.companyIds}
          valueFormatter={(item) => item.id}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              keyword: null,
              filterOnlyAccessible: false,
              enabled: null,
            },
          }}
          placeholder='Company'
          onChange={(newValue) => {
            changeFilter(
              'companyIds',
              newValue.map(({ value }) => value)
            );
          }}
        />
      </div>

      <div className='col-span-2'>
        <BusinessUnitLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.businessUnitIds}
          onChange={(newValue) => {
            changeFilter(
              'businessUnitIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              keyword: null,
              enabled: null,
            },
          }}
          placeholder='Business Unit'
        />
      </div>

      <div className='col-span-2'>
        <JobLevelSelect
          isMulti={true}
          onChange={(newValue) =>
            changeFilter(
              'levels',
              newValue.map(({ value }) => value)
            )
          }
          value={current.levels.map((item) => ({
            label: JobHistoryLevelLabels[item],
            value: item,
          }))}
        />
      </div>

      <div className='col-span-2'>
        <EmployeeLazySelect
          isMulti={true}
          className='select-has-min-w-menu'
          value={current.reviewerIds}
          valueFormatter={(item) => item.id}
          onChange={(newValue) => {
            changeFilter(
              'reviewerIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              filter: {
                keyword: null,
                departmentIds: [],
                companyIds: [],
                businessUnitIds: [],
                excludeAuthUser: false,
                contractTypes: [],
                jobIds: [],
                levels: [],
                locationIds: [],
                divisionIds: [],
                managerIds: [],
                statuses: [],
                isLeave: false,
                isOnSecondment: false,
                managerIncludesAllConnections: true,
                nationalityIds: [],
                roles: [],
              },
            },
          }}
          placeholder='Reviewer'
        />
      </div>

      <div className='col-span-2'>
        <EmployeeLazySelect
          isMulti={true}
          className='select-has-min-w-menu'
          value={current.primaryManagerIds}
          valueFormatter={(item) => item.id}
          onChange={(newValue) => {
            changeFilter(
              'primaryManagerIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              filter: {
                keyword: null,
                departmentIds: [],
                companyIds: [],
                businessUnitIds: [],
                excludeAuthUser: false,
                contractTypes: [],
                jobIds: [],
                levels: [],
                locationIds: [],
                divisionIds: [],
                managerIds: [],
                statuses: [],
                isLeave: false,
                isOnSecondment: false,
                managerIncludesAllConnections: true,
                nationalityIds: [],
                roles: [],
              },
            },
          }}
          placeholder='Primary Manager'
        />
      </div>
    </div>
  );
};
