import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportEmployeesMutationVariables = Types.Exact<{
  format: Types.EmployeeImportFormat;
  url: Types.Scalars['Url']['input'];
}>;


export type ImportEmployeesMutation = { __typename?: 'Mutation', importEmployees: Array<{ __typename?: 'EmployeeImportOutput', imported: number, sheetName: string, errors: Array<{ __typename?: 'EmployeeImportOutputErrorOther', message: string } | { __typename?: 'EmployeeImportOutputErrorRow', message: string, rowNumber: number, columnName: string | null }> }> };


export const ImportEmployeesDocument = gql`
    mutation ImportEmployees($format: EmployeeImportFormat!, $url: Url!) {
  importEmployees(format: $format, url: $url) {
    imported
    sheetName
    errors {
      ... on EmployeeImportOutputError {
        message
      }
      ... on EmployeeImportOutputErrorRow {
        rowNumber
        message
        columnName
      }
    }
  }
}
    `;
export type ImportEmployeesMutationFn = Apollo.MutationFunction<ImportEmployeesMutation, ImportEmployeesMutationVariables>;

/**
 * __useImportEmployeesMutation__
 *
 * To run a mutation, you first call `useImportEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEmployeesMutation, { data, loading, error }] = useImportEmployeesMutation({
 *   variables: {
 *      format: // value for 'format'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useImportEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<ImportEmployeesMutation, ImportEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportEmployeesMutation, ImportEmployeesMutationVariables>(ImportEmployeesDocument, options);
      }
export type ImportEmployeesMutationHookResult = ReturnType<typeof useImportEmployeesMutation>;
export type ImportEmployeesMutationResult = Apollo.MutationResult<ImportEmployeesMutation>;
export type ImportEmployeesMutationOptions = Apollo.BaseMutationOptions<ImportEmployeesMutation, ImportEmployeesMutationVariables>;