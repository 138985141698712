import { ApolloError } from '@apollo/client';

export class ApiUtils {
  static getErrorMessage = (error: unknown) => {
    if (error instanceof ApolloError) {
      const code =
        error.graphQLErrors?.[error?.graphQLErrors.length - 1]?.extensions
          ?.detail;

      if (code && typeof code === 'string') {
        return code;
      }

      if (error.message) {
        return error.message;
      }
    }

    return 'An error has occurred.';
  };
}
