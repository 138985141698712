import { ReactNode } from 'react';
import { EMPTY_DEFAULT } from 'src/components/shared';

interface Props {
  label: string;
  value?: string | ReactNode | null;
}

export const ReadonlyDetail = ({ label, value }: Props) => {
  return (
    <div className='flex flex-col space-y-2'>
      <p className='text-gray-7'>{label}</p>
      <p className='font-semibold'>{value || EMPTY_DEFAULT}</p>
    </div>
  );
};
