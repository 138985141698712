import { Card } from '@/shared/components';
import { ProbationReviewResult } from '@/shared/graphql';
import { DateUtils, StringUtils } from '@/shared/utils';
import { Textarea } from '@any-ui-react/core';
import classNames from 'classnames';
import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ProbationReviewDetailType } from 'src/types/probation-review-detail';
import { ProbationResult } from './ProbationResult';

interface Props {
  hiredDate: string | null;
  probationEndDate: string | null;
  overdueDays: number;
  isSubmitted?: boolean;
  result?: ProbationReviewResult | null;
}

export const ProbationExpectations = ({
  hiredDate,
  probationEndDate,
  overdueDays,
  isSubmitted,
  result,
}: Props) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<ProbationReviewDetailType>();

  const daysRemaining = useMemo(() => {
    if (!probationEndDate || isSubmitted) return -1;
    return differenceInDays(new Date(probationEndDate), new Date());
  }, [isSubmitted, probationEndDate]);

  return (
    <div>
      <div className='my-8 text-lg font-semibold'>
        Expectations for Probation Period
      </div>
      <Card className='flex flex-col space-y-4'>
        <div className='flex space-x-1 text-sm'>
          <span>Hired Date:</span>
          <span className='font-semibold'>
            {DateUtils.formatDate(hiredDate, 'MMM dd, yyyy')}
          </span>
        </div>
        <div className='flex items-end space-x-1 text-sm'>
          <span>Scheduled Probation End Date</span>
          <span className='mb-0.5 text-2xs text-gray-7'>
            (Review Deadline for the Last Review)
          </span>
          :
          <span className='font-bold'>
            {DateUtils.formatDate(probationEndDate, 'MMM dd, yyyy')}
          </span>
          {daysRemaining > -1 && (
            <div className='pl-4 text-red-5'>
              {`Remaining ${daysRemaining} days`}
            </div>
          )}
        </div>

        {isSubmitted && result && (
          <div className='flex items-center space-x-1 text-sm'>
            <span>Review Result:</span> <ProbationResult result={result} />
          </div>
        )}

        <div className='relative'>
          <Textarea
            {...register('expectation')}
            label="Manager's Expectations for Probation Period"
            required
            minRows={5}
            error={errors.expectation?.message}
            disabled={isSubmitted}
            placeholder={`Provide your expectations for the probation period, specifying the areas, skills, or knowledge you expect him/her to develop. Additionally, clearly define the milestones you expect him/her to achieve in order to make a positive contribution to the team, department, or company as a whole.\n\n70% of learning happens through on-the-job experience, 20% of learning happens through managers and colleagues, 10% of learning happens via formal training experiences`}
            classNames={{
              input: classNames({
                '!text-gray-9': isSubmitted,
              }),
            }}
          />

          {overdueDays > 0 && (
            <span className='absolute right-0 top-0 text-red-5'>
              Overdue {overdueDays} {StringUtils.pluralize(overdueDays, 'day')}
            </span>
          )}
        </div>
      </Card>
    </div>
  );
};
