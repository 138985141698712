import { EmployeePersonalInformationFormDataType } from '@/shared/types';
import { create, enforce, skipWhen, test } from 'vest';
import 'vest/enforce/email';

export const personalInformationFormValidationSuite = create(
  ({
    gender,
    dateOfBirth,
    personalEmail,
    residentialCountryId,
  }: EmployeePersonalInformationFormDataType) => {
    test('gender', 'Gender is required', () => {
      enforce(gender).isNotEmpty();
    });

    test('residentialCountryId', 'Residential Location is required', () => {
      enforce(residentialCountryId).isNotEmpty();
    });

    skipWhen(!personalEmail, () => {
      test('personalEmail', 'Personal Email is not valid', () => {
        enforce(personalEmail).isEmail();
      });
    });
  }
);
