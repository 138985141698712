import { useModals } from '@any-ui-react/modals';
import { PropsWithChildren } from 'react';
import { v4 as uuid } from 'uuid';
import { EmployeeListImportModalForm } from './EmployeeListImportModalForm';

export const EmployeeListImportModal = ({ children }: PropsWithChildren) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();

  const onClick = () => {
    openModal({
      modalId,
      size: 'md',
      title: 'Import',
      children: (
        <EmployeeListImportModalForm onClose={() => closeModal(modalId)} />
      ),
    });
  };

  return <div onClick={onClick}>{children}</div>;
};
