import { Loader } from '@any-ui-react/core';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleOfflineOAuthCallbackMutation } from 'src/pages/employees/list/graphql';
import { useAsyncEffect } from 'use-async-effect';
import { getStorageItem } from '../../../utils';
import { AUTH_STATE_KEY } from '../../login/Login';

export const PerformanceReviewExportCallback = () => {
  const navigate = useNavigate();
  const authState = getStorageItem(AUTH_STATE_KEY) ?? '';

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const code = query.get('code') ?? '';
  const isValidState = authState === query.get('state');

  const [googleOfflineOAuthCallback] = useGoogleOfflineOAuthCallbackMutation({
    onCompleted: async () => {
      navigate('/performance-review/results');
    },
  });

  useAsyncEffect(async () => {
    if (!authState || !code || !isValidState) return;
    await googleOfflineOAuthCallback({
      variables: {
        code: code,
        redirectUri: process.env.REACT_APP_PERFORMANCE_REVIEW_CALLBACK_URL,
      },
    });
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};
