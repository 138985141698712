import { useMemo } from 'react';

import { LocationType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { GreenCheckIcon, RedCrossIcon } from '../../../shared/icons';
import { NoResult } from '../../../shared/no-result';
import { Table, TableLayout, TableProps } from '../../../shared/table';
import { LocationModal } from '../location-modal';

interface LocationTableProps {
  loading: boolean;
  items: LocationType[];
}

export const LocationTable = ({ items, loading }: LocationTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          minWidth: '15rem',
          cellRender: (location: LocationType) => {
            return (
              <LocationModal location={location}>
                <span className='text-link hover:cursor-pointer'>
                  {location.name}
                </span>
              </LocationModal>
            );
          },
        },
        {
          headerRender: () => 'Enabled',
          accessor: 'enabled',
          cellRender: ({ enabled }: LocationType) => {
            return (
              <span>{enabled ? <GreenCheckIcon /> : <RedCrossIcon />}</span>
            );
          },
        },
        {
          headerRender: () => 'Created Date',
          accessor: 'createdDate',
          cellRender: ({ createdAt }: LocationType) => {
            return <span>{DateUtils.formatDatetime(createdAt)}</span>;
          },
        },
        {
          headerRender: () => 'Updated Date',
          accessor: 'updatedAt',
          cellRender: ({ updatedAt }: LocationType) => {
            return <span>{DateUtils.formatDatetime(updatedAt)}</span>;
          },
        },
      ] as TableLayout<LocationType>[],
    []
  );

  const tableCommonProps: TableProps<LocationType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title='No Data Found'
        description='Try changing the filters or search terms'
      />
    ),
  };

  return (
    <>
      <Table<LocationType> {...tableCommonProps} page={1} allowFloatingHeader />
    </>
  );
};
