import { Table } from '@/shared/components';
import {
  ReportNumberOfEmployeeAllSubtotal,
  ReportNumberOfEmployeeAllYearMonth,
  useReportNumberOfEmployeeAllQuery,
} from '@/shared/graphql';
import { FilterProps } from '@/shared/hooks';
import { NumberOfEmployeesFilterType } from '@/shared/types';
import { useMemo } from 'react';
import { TableUtils } from '../../utils';

type ClientReportNumberOfEmployeeAllYearMonth = {
  metric: string;
  joined: number;
  left: number;
  total: number;
  subtotal: number;
  h1?: number;
  h2?: number;
};

export const NumberOfEmployeesAllTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const metrics = useMemo(() => ['total', 'joined', 'left'], []);

  const { data, loading } = useReportNumberOfEmployeeAllQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = TableUtils.generateColumnNames(
    Number(filters.current.year)
  );

  const tableData: ClientReportNumberOfEmployeeAllYearMonth[] = useMemo(
    () =>
      metrics.map((metric: string) => {
        const yearMonthData = Object.fromEntries(
          yearMonths.map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeAll.items.find(
              (item) => item.yearMonth === yearMonth
            )?.[metric as keyof ReportNumberOfEmployeeAllYearMonth] ?? 0,
          ])
        );

        return {
          metric: metric.charAt(0).toUpperCase() + metric.slice(1),
          joined: yearMonthData['joined'] ?? 0,
          left: yearMonthData['left'] ?? 0,
          total: yearMonthData['total'] ?? 0,
          ...yearMonthData,
          subtotal:
            data?.reportNumberOfEmployeeAll.subtotal?.[
              metric as keyof ReportNumberOfEmployeeAllSubtotal
            ],
        };
      }),
    [
      data?.reportNumberOfEmployeeAll.items,
      data?.reportNumberOfEmployeeAll.subtotal,
      metrics,
      yearMonths,
    ]
  );

  const items = [
    ...tableData,
    {
      metric: 'Turnover Rate',
      joined: 0,
      left: 0,
      total: 0,
      h1: data?.reportNumberOfEmployeeAll.subtotal.h1TurnOverRate,
      h2: data?.reportNumberOfEmployeeAll.subtotal.h2TurnOverRate,
      subtotal: data?.reportNumberOfEmployeeAll.subtotal.turnOverRate,
    },
  ];

  const columns = useMemo(
    () => [
      {
        headerRender: () => <span />,
        accessor: 'metric',
        tdClassName: '!bg-disabled border w-[100px]',
        thClassName: '!bg-disabled border w-[100px]',
        cellRender: (row: ClientReportNumberOfEmployeeAllYearMonth) => (
          <div className='w-[100px] text-2xs'>{row.metric}</div>
        ),
      },
      {
        headerRender: () => (
          <div className='flex h-12'>
            {[...yearMonths].slice(0, 6).map((ym) => (
              <div className='flex h-full w-full items-center justify-center border-r px-3 text-4xs'>
                {ym}
              </div>
            ))}
          </div>
        ),
        accessor: 'h1',
        thClassName: loading ? undefined : 'p-0',
        tdClassName: loading ? undefined : 'h-10 p-0',
        cellRender: (
          row: ClientReportNumberOfEmployeeAllYearMonth,
          index: number
        ) => {
          if (index < 3)
            return (
              <div className='flex h-10'>
                {[...yearMonths].slice(0, 6).map((yearMonth) => (
                  <div className='flex w-full items-center justify-end border-r px-3 text-2xs'>
                    {
                      row[
                        yearMonth as keyof ClientReportNumberOfEmployeeAllYearMonth
                      ]
                    }
                  </div>
                ))}
              </div>
            );

          return (
            <div className='flex h-full w-full items-center justify-center border-r px-3 text-2xs'>
              Jan.~Jun.: {row.h1}%
            </div>
          );
        },
      },
      {
        headerRender: () => (
          <div className='flex h-12'>
            {[...yearMonths].slice(6, 12).map((ym) => (
              <div className='flex w-full items-center justify-center border-r px-3 text-4xs'>
                {ym}
              </div>
            ))}
          </div>
        ),
        accessor: 'h2',
        thClassName: 'p-0',
        tdClassName: 'h-10 p-0',
        cellRender: (
          row: ClientReportNumberOfEmployeeAllYearMonth,
          index: number
        ) => {
          if (index < 3)
            return (
              <div className='flex h-10'>
                {[...yearMonths].slice(6, 12).map((yearMonth) => (
                  <div className='flex h-full w-full items-center justify-end border-r px-3 text-2xs'>
                    {
                      row[
                        yearMonth as keyof ClientReportNumberOfEmployeeAllYearMonth
                      ]
                    }
                  </div>
                ))}
              </div>
            );

          return (
            <div className='flex h-full w-full items-center justify-center border-r text-2xs'>
              Jul.~Dec.: {row.h2}%
            </div>
          );
        },
      },

      {
        headerRender: () => <div className='text-right text-4xs'>Subtotal</div>,
        accessor: 'subtotal',
        tdClassName: 'h-10 !w-[50px]',
        thClassName: '!w-[50px]',
        maxWidth: '100px',
        cellRender: (
          row: ClientReportNumberOfEmployeeAllYearMonth,
          index: number
        ) => (
          <div className='text-right text-2xs'>
            {row.subtotal}
            {index > 2 ? '%' : ''}
          </div>
        ),
      },
    ],
    [loading, yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeAllYearMonth>
        layout={columns}
        data={items || []}
        loading={loading}
      />
    </div>
  );
};
