import { JobHistoryLevel } from '@/shared/graphql';
import {
  ActionMeta,
  MultiValue,
  PropsValue,
  Select,
  useMultiSelect,
} from '@any-ui-react/select';

interface SelectProps {
  isMulti?: boolean;
  value:
    | string
    | number
    | boolean
    | PropsValue<{ label: string; value: JobHistoryLevel }>
    | undefined;
  onChange:
    | ((
        newValue: MultiValue<{
          label: string;
          value: JobHistoryLevel;
        }>,
        actionMeta: ActionMeta<{
          label: string;
          value: JobHistoryLevel;
        }>
      ) => void)
    | undefined;
  error?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
}

const orderedJobHistoryLevel = [
  JobHistoryLevel.L0,
  JobHistoryLevel.L1,
  JobHistoryLevel.L2,
  JobHistoryLevel.Ic3,
  JobHistoryLevel.L3,
  JobHistoryLevel.Ic4,
  JobHistoryLevel.L4,
  JobHistoryLevel.Ic5,
  JobHistoryLevel.L5,
  JobHistoryLevel.Ic6,
  JobHistoryLevel.L6,
  JobHistoryLevel.Ic7,
  JobHistoryLevel.L7,
  JobHistoryLevel.CClass,
];

export const JobHistoryLevelLabels = {
  [JobHistoryLevel.Ic3]: 'Ic3',
  [JobHistoryLevel.Ic4]: 'Ic4',
  [JobHistoryLevel.Ic5]: 'Ic5',
  [JobHistoryLevel.Ic6]: 'Ic6',
  [JobHistoryLevel.Ic7]: 'Ic7',
  [JobHistoryLevel.L0]: 'L0',
  [JobHistoryLevel.L1]: 'L1',
  [JobHistoryLevel.L2]: 'L2',
  [JobHistoryLevel.L3]: 'L3',
  [JobHistoryLevel.L4]: 'L4',
  [JobHistoryLevel.L5]: 'L5',
  [JobHistoryLevel.L6]: 'L6',
  [JobHistoryLevel.L7]: 'L7',
  [JobHistoryLevel.CClass]: 'C-Class',
};

export const JobLevelSelect = ({
  onChange,
  value,
  isMulti = true,
  placeholder = 'Select Level',
  ...rest
}: SelectProps) => {
  const options = Object.entries(JobHistoryLevel)
    .map(([key, value]) => {
      return {
        label: JobHistoryLevelLabels[value],
        value: value,
      };
    })
    .sort(
      (a, b) =>
        orderedJobHistoryLevel.indexOf(a.value) -
        orderedJobHistoryLevel.indexOf(b.value)
    );

  const multiSelectProps = useMultiSelect({
    getSelectedLabel: (count) => `Levels (${count})`,
    options: options,
  });

  const selectProps = isMulti ? multiSelectProps : { options: options };

  return (
    <Select
      placeholder={placeholder}
      isSearchable={true}
      isClearable={true}
      onChange={onChange}
      value={value}
      {...rest}
      {...selectProps}
    />
  );
};
