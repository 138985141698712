import { EmployeeRole, useGetCurrentEmployeeQuery } from '@/shared/graphql';
import { useMemo } from 'react';
import { useAuthContext, useEmployeeDetailContext } from '../../contexts';

export const useEmployeeAccess = () => {
  const { authUser } = useAuthContext();
  const { data, loading: currentUserLoading } = useGetCurrentEmployeeQuery();
  const { employeeDetail } = useEmployeeDetailContext();

  const isSuperAdmin = useMemo(
    () => authUser.role === EmployeeRole.SuperAdmin,
    [authUser.role]
  );
  const isAdminPrivate = useMemo(
    () => authUser.role === EmployeeRole.AdminPrivate,
    [authUser.role]
  );
  const isAdminStandard = useMemo(
    () => authUser.role === EmployeeRole.AdminStandard,
    [authUser.role]
  );
  const isBasic = useMemo(
    () => authUser.role === EmployeeRole.Basic,
    [authUser.role]
  );
  const isMyOwnData = useMemo(
    () => employeeDetail.id === data?.currentEmployee.id,
    [data?.currentEmployee.id, employeeDetail.id]
  );

  const isDirectReport = useMemo(() => {
    const isPrimaryManager =
      employeeDetail.generalInformation.primaryManagerId ===
      data?.currentEmployee.id;
    const isDottedLineManager =
      employeeDetail.generalInformation.dottedLineManagerId ===
      data?.currentEmployee.id;
    return isPrimaryManager || isDottedLineManager;
  }, [
    data?.currentEmployee.id,
    employeeDetail.generalInformation.dottedLineManagerId,
    employeeDetail.generalInformation.primaryManagerId,
  ]);

  const isAssignedCompany = useMemo(() => {
    const authenticatedEmployeeCompanyIds =
      data?.currentEmployee.permission?.companyIds ?? [];
    const employeeCompanyId = employeeDetail.generalInformation.companyId;

    return authenticatedEmployeeCompanyIds.includes(employeeCompanyId);
  }, [
    data?.currentEmployee.permission?.companyIds,
    employeeDetail.generalInformation.companyId,
  ]);

  const isAnAssignor = useMemo(
    () =>
      data?.currentEmployee.permission?.employeeIds.findIndex(
        (item) => item === employeeDetail.generalInformation.id
      ) !== -1,
    [
      data?.currentEmployee.permission?.employeeIds,
      employeeDetail.generalInformation.id,
    ]
  );

  const hasGeneralInformationEditAccess = useMemo(() => {
    const validTypes =
      [EmployeeRole.Basic, EmployeeRole.Advanced].includes(authUser.role) ===
      false;

    return (
      isSuperAdmin ||
      ((isMyOwnData || isDirectReport || isAnAssignor || isAssignedCompany) &&
        validTypes)
    );
  }, [
    authUser.role,
    isAnAssignor,
    isDirectReport,
    isMyOwnData,
    isSuperAdmin,
    isAssignedCompany,
  ]);

  const hasPersonalInformationViewAccess = useMemo(() => {
    const validTypes =
      [EmployeeRole.Basic, EmployeeRole.Advanced].includes(authUser.role) ===
      false;

    return (
      isSuperAdmin ||
      isMyOwnData ||
      ((isAnAssignor || isDirectReport || isAssignedCompany) && validTypes)
    );
  }, [
    authUser.role,
    isSuperAdmin,
    isMyOwnData,
    isAnAssignor,
    isDirectReport,
    isAssignedCompany,
  ]);

  const hasPersonalInformationEditAccess = useMemo(() => {
    const validTypes =
      [EmployeeRole.Basic, EmployeeRole.Advanced].includes(authUser.role) ===
      false;

    return (
      isSuperAdmin ||
      ((isMyOwnData || isAnAssignor || isDirectReport || isAssignedCompany) &&
        validTypes)
    );
  }, [
    authUser.role,
    isAnAssignor,
    isMyOwnData,
    isSuperAdmin,
    isDirectReport,
    isAssignedCompany,
  ]);

  const hasPersonalInformationDeleteAccess = useMemo(() => {
    return isSuperAdmin;
  }, [isSuperAdmin]);

  const hasOffBoardingViewAccess = useMemo(() => {
    return (
      isSuperAdmin ||
      isAdminPrivate ||
      (isAdminStandard && isAssignedCompany && !isMyOwnData)
    );
  }, [
    isSuperAdmin,
    isAdminPrivate,
    isAdminStandard,
    isAssignedCompany,
    isMyOwnData,
  ]);

  const hasJobContractHistoryViewAccess = useMemo(() => {
    const validTypes =
      [EmployeeRole.Basic, EmployeeRole.Advanced].includes(authUser.role) ===
      false;
    return (
      isSuperAdmin ||
      isMyOwnData ||
      ((isDirectReport || isAnAssignor || isAssignedCompany) && validTypes)
    );
  }, [
    authUser.role,
    isSuperAdmin,
    isMyOwnData,
    isDirectReport,
    isAnAssignor,
    isAssignedCompany,
  ]);

  const hasPermissionViewAccess = useMemo(() => {
    return (
      isSuperAdmin ||
      (isAdminPrivate && (isAssignedCompany || isDirectReport || isMyOwnData))
    );
  }, [
    isSuperAdmin,
    isAdminPrivate,
    isAssignedCompany,
    isDirectReport,
    isMyOwnData,
  ]);

  const hasPermissionEditAccess = useMemo(() => {
    return hasPermissionViewAccess && !isMyOwnData;
  }, [hasPermissionViewAccess, isMyOwnData]);

  const hasNotificationPageAccess = useMemo(() => {
    if (!(isSuperAdmin || isAdminPrivate || isAdminStandard)) {
      return false;
    }

    return !!data?.currentEmployee.currentContract?.company.countryId;
  }, [
    data?.currentEmployee.currentContract?.company.countryId,
    isAdminPrivate,
    isAdminStandard,
    isSuperAdmin,
  ]);

  const hasProbationReviewViewAccess = useMemo(() => {
    return (
      (isMyOwnData || isDirectReport || isAssignedCompany || isSuperAdmin) &&
      employeeDetail.generalInformation.needsProbationReview
    );
  }, [
    isAssignedCompany,
    isDirectReport,
    isMyOwnData,
    isSuperAdmin,
    employeeDetail.generalInformation.needsProbationReview,
  ]);

  const hasProbationReviewModifyAccess = useMemo(() => {
    return (
      isMyOwnData ||
      isSuperAdmin ||
      isDirectReport ||
      (isAdminPrivate && isAssignedCompany)
    );
  }, [
    isAdminPrivate,
    isAssignedCompany,
    isDirectReport,
    isMyOwnData,
    isSuperAdmin,
  ]);

  const hasPerformanceReviewViewAccess = useMemo(() => {
    return isMyOwnData || isDirectReport || isAssignedCompany || isSuperAdmin;
  }, [isAssignedCompany, isDirectReport, isMyOwnData, isSuperAdmin]);

  const hasPerformanceReviewModifyAccess = useMemo(() => {
    return (
      isMyOwnData ||
      isSuperAdmin ||
      isDirectReport ||
      (isAdminPrivate && isAssignedCompany)
    );
  }, [
    isAdminPrivate,
    isAssignedCompany,
    isDirectReport,
    isMyOwnData,
    isSuperAdmin,
  ]);

  const hasEmployeeAddAccess = useMemo(() => {
    return isSuperAdmin || isAdminStandard || isAdminPrivate;
  }, [isAdminPrivate, isAdminStandard, isSuperAdmin]);

  const hasResultsListAccess = useMemo(() => {
    return isSuperAdmin || isAdminPrivate;
  }, [isAdminPrivate, isSuperAdmin]);

  const hasReportsPageAccess = useMemo(() => {
    return !isBasic;
  }, [isBasic]);

  const hasPerformanceReviewStageAccess = useMemo(() => {
    return isSuperAdmin || isAdminPrivate;
  }, [isAdminPrivate, isSuperAdmin]);

  return {
    hasEmployeeDeleteAccess: isSuperAdmin,
    hasGeneralInformationEditAccess,
    hasPersonalInformationViewAccess,
    hasPersonalInformationEditAccess,
    hasPersonalInformationDeleteAccess,
    hasOffBoardingViewAccess,
    hasOffBoardingEditAccess: hasOffBoardingViewAccess,
    hasJobContractHistoryViewAccess, // reuse variable, as same logic
    hasJobContractHistoryEditAccess: hasPersonalInformationEditAccess,
    hasJobContractHistoryDeleteAccess: hasPersonalInformationDeleteAccess,
    hasExternalAccountsViewAccess: hasOffBoardingViewAccess,
    hasPermissionViewAccess,
    hasPermissionEditAccess,
    loading: currentUserLoading,
    hasNotificationCountryAccess: isSuperAdmin,
    hasNotificationPageAccess,
    hasProbationReviewViewAccess,
    hasProbationReviewModifyAccess,
    isMyOwnData,
    currentContractCountryId:
      data?.currentEmployee.currentContract?.company.countryId,
    hasPerformanceReviewViewAccess,
    hasPerformanceReviewModifyAccess,
    hasEmployeeAddAccess,
    hasResultsListAccess,
    hasReportsPageAccess,
    hasPerformanceReviewStageAccess,
  };
};
