import { useAsyncEffect } from 'use-async-effect';

import { AUTH_STATE_KEY } from './Login';

import { useAuthContext } from '@/shared/contexts';
import { useSignInCallbackMutation } from '@/shared/graphql';
import { Loader } from '@any-ui-react/core';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppEnvironment,
  getStorageItem,
  setAuthUserInSession,
} from '../../utils';

export const SignInCallback = () => {
  const { setAuthUser } = useAuthContext();
  const navigate = useNavigate();
  const authState = getStorageItem(AUTH_STATE_KEY) ?? '';

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const code = query.get('code') ?? '';
  const isValidState = authState === query.get('state');

  const [authCallback] = useSignInCallbackMutation({
    onCompleted: async (data) => {
      try {
        if (!data?.signInCallback) return;

        const authUser = setAuthUserInSession(data?.signInCallback ?? '');

        setAuthUser({
          ...authUser,
        });

        const redirect = getStorageItem('redirect');
        navigate(redirect || '/');
      } catch (e) {
        console.error(e);
        navigate('/login');
      }
    },
  });

  useAsyncEffect(async () => {
    if (!authState || !code || !isValidState) return;
    await authCallback({
      variables: {
        code: code,
        redirectUri:
          process.env.REACT_APP_ENVIRONMENT === AppEnvironment.LOCAL
            ? process.env.REACT_APP_AUTH_CALLBACK_URL
            : undefined,
      },
    });
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};
