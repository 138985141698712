import {
  CountryLazySelect,
  DEFAULT_PAGE_SIZE,
  toast,
} from '@/shared/components';
import {
  CompanyProbationReviewTermMode,
  useCreateCompanyMutation,
  useListGoogleOrgUnitPathsQuery,
  useUpdateCompanyMutation,
} from '@/shared/graphql';
import { CompanyType } from '@/shared/types';
import { CompanyError } from '@/shared/utils';
import {
  Button,
  ModalFooter,
  OnOffSwitch,
  Radio,
  Select,
  TextInput,
} from '@any-ui-react/core';
import { ApolloError, BaseMutationOptions } from '@apollo/client';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  CompanyFormData,
  companyFormValidationSuite,
} from './company-form.validator';

export interface CompanyFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  defaultValues?: CompanyType;
}

export const CompanyForm = ({
  defaultValues,
  onSuccess,
  onCancel,
}: CompanyFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    setError,
  } = useForm<CompanyFormData>({
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          countryId: defaultValues.country.id,
          probationReviewTerm: {
            mode: defaultValues.probationReviewTerm.mode,
            lengthInDay:
              defaultValues.probationReviewTerm.mode ===
              CompanyProbationReviewTermMode.Day
                ? defaultValues.probationReviewTerm.length
                : 0,
            lengthInMonth:
              defaultValues.probationReviewTerm.mode ===
              CompanyProbationReviewTermMode.Month
                ? defaultValues.probationReviewTerm.length
                : 0,
          },
        }
      : { enabled: true },
    resolver: vestResolver(companyFormValidationSuite),
  });

  const { data: orgUnitPathsData, loading: orgUnitPathLoading } =
    useListGoogleOrgUnitPathsQuery();

  const onError = (error: ApolloError, clientOptions?: BaseMutationOptions) => {
    if (error.message === CompanyError.COMPANY_NAME_IS_NOT_UNIQUE) {
      setError('name', { message: 'Name exists already' });
    } else {
      toast.error(error.message);
    }
  };

  const [createCompany] = useCreateCompanyMutation({
    onError,
    onCompleted: async (data) => {
      if (data.createCompany.id) {
        onSuccess();
      }
    },
  });
  const [updateCompany] = useUpdateCompanyMutation({
    onError,
    onCompleted: (data) => {
      if (data.updateCompany.id) {
        onSuccess();
      }
    },
  });

  const [enabled, mode] = watch(['enabled', 'probationReviewTerm.mode']);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        const isNewRecord = defaultValues === undefined;
        if (isNewRecord) {
          await createCompany({
            variables: {
              input: {
                name: formValues.name,
                googleOrgUnitPath: formValues.googleOrgUnitPath,
                enabled: formValues.enabled || false,
                countryId: formValues.countryId,
                probationReviewTimes: formValues.probationReviewTimes,
                probationReviewTerm: {
                  mode: formValues.probationReviewTerm.mode,
                  length:
                    formValues.probationReviewTerm.mode ===
                    CompanyProbationReviewTermMode.Day
                      ? formValues.probationReviewTerm.lengthInDay
                      : formValues.probationReviewTerm.lengthInMonth,
                },
              },
            },
          });
        } else {
          await updateCompany({
            variables: {
              input: {
                id: defaultValues.id,
                countryId: formValues.countryId,
                name: formValues.name,
                googleOrgUnitPath: formValues.googleOrgUnitPath,
                enabled: formValues.enabled,
                probationReviewTimes: formValues.probationReviewTimes,
                probationReviewTerm: {
                  mode: formValues.probationReviewTerm.mode,
                  length:
                    formValues.probationReviewTerm.mode ===
                    CompanyProbationReviewTermMode.Day
                      ? formValues.probationReviewTerm.lengthInDay
                      : formValues.probationReviewTerm.lengthInMonth,
                },
              },
            },
          });
        }
      }),
    [createCompany, defaultValues, handleSubmit, updateCompany]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <Controller
          control={control}
          name='countryId'
          render={({ field: { ...field } }) => (
            <CountryLazySelect
              isMulti={false}
              label='Country'
              required
              isSearchable
              error={errors.countryId?.message}
              valueFormatter={(item) => item.id}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  keyword: null,
                  hasCompanies: null,
                },
              }}
              placeholder={'Select Country'}
              {...field}
              onChange={(option) => {
                setValue('countryId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
            />
          )}
        />

        <TextInput
          {...register('name')}
          label={'Name'}
          error={errors.name?.message}
          placeholder={'Enter name'}
          required
        />

        <span className='col-span-2 font-semibold'>
          Probation Review Setting
        </span>
        <TextInput
          {...register('probationReviewTimes')}
          label={'Number of Probation Period'}
          error={errors.probationReviewTimes?.message}
          placeholder={'0'}
          className='col-span-2'
          required
        />
        <Controller
          control={control}
          name='probationReviewTerm.mode'
          render={({ field: { value, ...fields } }) => {
            return (
              <Radio.Group
                {...fields}
                className='col-span-2  gap-x-4'
                label={'Single Probation Period Duration'}
                error={errors.probationReviewTerm?.mode?.message}
                required
              >
                <Radio
                  className='pb-6 pt-5'
                  value={CompanyProbationReviewTermMode.Day}
                  label={
                    <TextInput
                      {...register('probationReviewTerm.lengthInDay')}
                      placeholder={'0'}
                      className='mt-[-0.5625rem]'
                      rightSection={
                        <span className='ml-[-0.5rem] w-6 text-center text-xs text-gray-6'>
                          days
                        </span>
                      }
                      disabled={mode === CompanyProbationReviewTermMode.Month}
                    />
                  }
                />
                <Radio
                  className='pb-4'
                  value={CompanyProbationReviewTermMode.Month}
                  label={
                    <TextInput
                      {...register('probationReviewTerm.lengthInMonth')}
                      placeholder={'0'}
                      className='mt-[-0.5625rem]'
                      rightSection={
                        <span className='ml-[-1.75rem] w-6 text-center text-xs text-gray-6'>
                          month
                        </span>
                      }
                      disabled={mode === CompanyProbationReviewTermMode.Day}
                    />
                  }
                />
              </Radio.Group>
            );
          }}
        />
        <OnOffSwitch
          key={'12'}
          checked={enabled}
          onChange={(e) => {
            setValue('enabled', e.currentTarget.checked);
          }}
          label='Show on the dropdown'
        />
        <Controller
          control={control}
          name='googleOrgUnitPath'
          render={({ field: { ...field } }) => (
            <Select
              clearable
              searchable
              required
              {...field}
              label={'Google Org Unit Path'}
              error={errors.googleOrgUnitPath?.message}
              data={(orgUnitPathsData &&
              orgUnitPathsData.listGoogleOrgUnitPaths.length > 0
                ? orgUnitPathsData.listGoogleOrgUnitPaths
                : []
              )?.map((item) => ({
                label: item,
                value: item,
              }))}
              className='col-span-2'
              placeholder={'Select a Google Organization Unit'}
              disabled={orgUnitPathLoading}
            />
          )}
        />
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
