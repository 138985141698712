import {
  CardSection,
  JobHistoryLevelLabels,
  ReadonlyDetail,
} from '@/shared/components';
import { EmployeeDetailType } from '@/shared/types';

export const CurrentPosition = ({
  employee,
  hasJobContractHistoryViewAccess,
}: {
  employee: EmployeeDetailType;
  hasJobContractHistoryViewAccess: boolean;
}) => {
  const employeeGeneralInformation = employee.generalInformation;

  return (
    <CardSection>
      <div className='mb-6'>
        <h1 className='text-lg font-semibold'>Current Job</h1>
        {hasJobContractHistoryViewAccess && (
          <p className='text-[0.75rem] text-gray-7'>
            You can see and edit histories at "Job history" section
          </p>
        )}
      </div>
      <div className='form-fieldset'>
        <ReadonlyDetail
          label='Level'
          value={
            employeeGeneralInformation.level
              ? JobHistoryLevelLabels[employeeGeneralInformation.level]
              : null
          }
        />
        <ReadonlyDetail
          label='Office Location'
          value={employeeGeneralInformation.location}
        />
        <ReadonlyDetail
          label='Business Unit'
          value={employeeGeneralInformation.businessUnit}
        />
        <ReadonlyDetail
          label='Department'
          value={employeeGeneralInformation.department}
        />

        <ReadonlyDetail label='Team' value={employeeGeneralInformation.team} />

        <ReadonlyDetail label='Job' value={employeeGeneralInformation.job} />
        <ReadonlyDetail
          label='Job Title'
          value={employeeGeneralInformation.jobTitle}
        />
      </div>
    </CardSection>
  );
};
