import {
  EmployeeIcon,
  EvaluationIcon,
  ExternalLinkIcon,
  HelpIcon,
  ProbationReviewIcon,
  ReportIcon,
  SettingIcon,
} from '@/shared/components';
import { useAuthContext } from '@/shared/contexts';
import { EmployeeRole } from '@/shared/graphql';
import { useEmployeeAccess } from '@/shared/hooks';
import * as Accordion from '@radix-ui/react-accordion';
import { memo } from 'react';
import { NavigationLink } from './NavigationLink';

export const NavigationLinks = memo(() => {
  const { authUser } = useAuthContext();
  const {
    hasNotificationPageAccess,
    hasResultsListAccess,
    hasReportsPageAccess,
    hasPerformanceReviewStageAccess,
  } = useEmployeeAccess();

  return (
    <>
      <Accordion.Root
        type='multiple'
        className='px-2 text-xs font-semibold'
        value={['item-1', 'item-2', 'item-4', 'item-5']}
      >
        <Accordion.Item value='item-1'>
          <Accordion.Header>
            <NavigationLink
              name='Employees'
              href='/employees'
              icon={EmployeeIcon}
            />
          </Accordion.Header>
          {![EmployeeRole.Basic, EmployeeRole.Advanced].includes(
            authUser.role
          ) && (
            <Accordion.Content className='flex flex-col'>
              <NavigationLink
                name='Add Employee'
                href='/employees/add'
                className='pl-8'
              />
            </Accordion.Content>
          )}
          <Accordion.Content className='flex flex-col'>
            <NavigationLink
              name='Organization Chart'
              href='/employees/organization-chart'
              className='pl-8'
            />
          </Accordion.Content>
        </Accordion.Item>
        <Accordion.Item value='item-3'>
          <NavigationLink
            name='Probation Review'
            href='/probation-review'
            icon={ProbationReviewIcon}
          />
        </Accordion.Item>
        <Accordion.Item value='item-2'>
          <Accordion.Header>
            <NavigationLink
              name='Performance Review'
              href='/performance-review'
              icon={EvaluationIcon}
            />
          </Accordion.Header>

          {hasResultsListAccess && (
            <Accordion.Content className='flex flex-col'>
              <NavigationLink
                name='Results List'
                href='/performance-review/results'
                className='pl-8'
              />
            </Accordion.Content>
          )}
        </Accordion.Item>
        {hasReportsPageAccess && (
          <Accordion.Item value='item-4'>
            <Accordion.Header>
              <NavigationLink
                name='Report'
                href='/report'
                icon={ReportIcon}
                navigable={false}
              />
            </Accordion.Header>
            <Accordion.Content className='flex flex-col'>
              <NavigationLink
                name='Number of Employees'
                href='/report/number-of-employees'
                className='pl-8'
              />
              <NavigationLink
                name='Demographic Report'
                href='/report/demographic-report'
                className='pl-8'
              />
            </Accordion.Content>
          </Accordion.Item>
        )}
        {![EmployeeRole.Basic, EmployeeRole.Advanced].includes(
          authUser.role
        ) && (
          <Accordion.Item value='item-5' className='mb-1.5'>
            <Accordion.Header>
              <NavigationLink
                name='Setting'
                href='/setting'
                icon={SettingIcon}
                navigable={false}
              />
            </Accordion.Header>
            <Accordion.Content className='flex flex-col'>
              {hasPerformanceReviewStageAccess && (
                <NavigationLink
                  name='Performance Review Stage'
                  href='/setting/performance-review-stage'
                  className='pl-8'
                />
              )}
              {hasNotificationPageAccess && (
                <NavigationLink
                  name='Notification'
                  href='/setting/notification'
                  className='pl-8'
                />
              )}
              <NavigationLink
                name='General'
                href='/setting/general'
                className='pl-8'
              />
            </Accordion.Content>
          </Accordion.Item>
        )}
        <Accordion.Item value='item-6'>
          <NavigationLink
            name='Help'
            href='https://sites.google.com/adasiaholdings.com/hrallabouts/home'
            icon={HelpIcon}
            leftIcon={ExternalLinkIcon}
            target='_blank'
          />
        </Accordion.Item>
      </Accordion.Root>
    </>
  );
});
