import { ReadonlyDetail } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';

export const SocialInsuranceDetails = () => {
  const { employeeDetail } = useEmployeeDetailContext();

  return (
    <div className='mb-6'>
      <fieldset className='form-fieldset'>
        <ReadonlyDetail
          label='Social Insurance Type'
          value={employeeDetail.personalInformation.taxCode}
        />

        <ReadonlyDetail
          label='Social Insurance Number'
          value={employeeDetail.personalInformation.socialInsuranceNumber}
        />

        <ReadonlyDetail
          label='Pension voluntary contribution rate'
          value={
            employeeDetail.personalInformation.pensionVoluntaryContributionRate
          }
        />

        <ReadonlyDetail
          label='Tax withholding rate'
          value={employeeDetail.personalInformation.taxWithholdingRate}
        />

        <ReadonlyDetail
          label='Notes'
          value={employeeDetail.personalInformation.socialInsuranceNotes}
        />

        <ReadonlyDetail
          label='Marital Status'
          value={employeeDetail.personalInformation.maritalStatus}
        />
      </fieldset>
    </div>
  );
};
