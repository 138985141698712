import loadable from '@loadable/component';
export const AlertIcon = loadable(() => import('./Alert'));
export const ChevronDownIcon = loadable(() => import('./ChevronDown'));
export const ChevronLeftIcon = loadable(() => import('./ChevronLeft'));
export const ChevronUpIcon = loadable(() => import('./ChevronUp'));
export const ClearIcon = loadable(() => import('./Clear'));
export const EditPenIcon = loadable(() => import('./EditPen'));
export const EloomiLogoIcon = loadable(() => import('./EloomiLogo'));
export const EmployeeIcon = loadable(() => import('./Employee'));
export const EvaluationIcon = loadable(() => import('./Evaluation'));
export const ExternalLinkIcon = loadable(() => import('./ExternalLink'));
export const FilterFunnelIcon = loadable(() => import('./FilterFunnel'));
export const GoogleLogoIcon = loadable(() => import('./GoogleLogo'));
export const GreenCheckIcon = loadable(() => import('./GreenCheck'));
export const GreenhouseLogoIcon = loadable(() => import('./GreenhouseLogo'));
export const HelpIcon = loadable(() => import('./Help'));
export const HubspotLogoIcon = loadable(() => import('./HubspotLogo'));
export const LockIcon = loadable(() => import('./Lock'));
export const MoreFilterIcon = loadable(() => import('./MoreFilter'));
export const ProbationReviewIcon = loadable(() => import('./ProbationReview'));
export const RedCrossIcon = loadable(() => import('./RedCross'));
export const ReportIcon = loadable(() => import('./Report'));
export const SignOutIcon = loadable(() => import('./SignOut'));
export const SearchIcon = loadable(() => import('./Search'));
export const SettingIcon = loadable(() => import('./Setting'));
export const SlackLogoIcon = loadable(() => import('./SlackLogo'));
export const TrashIcon = loadable(() => import('./Trash'));
export const UserSettingIcon = loadable(() => import('./UserSetting'));
export const StarIcon = loadable(() => import('./Star'));
