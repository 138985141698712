import { useMemo } from 'react';

import {
  EditPenIcon,
  EMPTY_DEFAULT,
  GreenCheckIcon,
  JobHistoryLevelLabels,
  JobHistoryTypeLabels,
  NoResult,
  Table,
  TableLayout,
  TableProps,
  TrashIcon,
  useDeleteRecordModal,
} from '@/shared/components';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeeJobHistoryType } from '@/shared/types';
import { ActionIcon } from '@any-ui-react/core';
import { JobHistoryModal } from '../job-history-modal';

interface JobHistoryTableProps {
  loading: boolean;
  items: EmployeeJobHistoryType[];
  onRemove: (id: string) => void;
  onUpdate: (data: EmployeeJobHistoryType) => Promise<boolean>;
}

export const JobHistoryTable = ({
  items,
  loading,
  onRemove,
  onUpdate,
}: JobHistoryTableProps) => {
  const { openDeleteRecordModal } = useDeleteRecordModal();

  const { hasJobContractHistoryEditAccess, hasJobContractHistoryDeleteAccess } =
    useEmployeeAccess();

  const layout = useMemo(
    () =>
      [
        ...(hasJobContractHistoryEditAccess
          ? [
              {
                headerRender: () => '',
                accessor: 'id',
                cellRender: (item: EmployeeJobHistoryType) => {
                  return (
                    <div className='flex space-x-3'>
                      <JobHistoryModal
                        className='ml-auto'
                        jobHistory={item}
                        onSuccess={onUpdate}
                      >
                        <ActionIcon variant='transparent' size='xs'>
                          <EditPenIcon />
                        </ActionIcon>
                      </JobHistoryModal>

                      {hasJobContractHistoryDeleteAccess && (
                        <ActionIcon
                          variant='transparent'
                          size='xs'
                          onClick={() => {
                            openDeleteRecordModal({
                              onSuccess: () => onRemove(item.id),
                            });
                          }}
                        >
                          <TrashIcon />
                        </ActionIcon>
                      )}
                    </div>
                  );
                },
              },
            ]
          : []),
        {
          headerRender: () => 'Start Date',
          accessor: 'effectiveDateStart',
          cellRender: ({ effectiveDateStart }) => effectiveDateStart,
        },
        {
          headerRender: () => 'End Date',
          accessor: 'effectiveDateEnd',
          cellRender: ({ effectiveDateEnd }) =>
            effectiveDateEnd || EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Type',
          accessor: 'jobType',
          cellRender: ({ type }) =>
            type ? JobHistoryTypeLabels[type] : EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Level',
          accessor: 'level',
          cellRender: ({ level }) =>
            level ? JobHistoryLevelLabels[level] : EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Office Location',
          accessor: 'location',
          cellRender: ({ location }) => location,
        },
        {
          headerRender: () => 'Division',
          accessor: 'division',
          cellRender: ({ division }) => division,
        },
        {
          headerRender: () => 'Business Unit',
          accessor: 'businessUnit',
          cellRender: ({ businessUnit }) => businessUnit,
        },
        {
          headerRender: () => 'Department',
          accessor: 'department',
          cellRender: ({ department }) => department,
        },
        {
          headerRender: () => 'Team',
          accessor: 'team',
          cellRender: ({ team }) => team,
        },
        {
          headerRender: () => 'Job',
          accessor: 'job',
          cellRender: ({ job }) => job,
        },
        {
          headerRender: () => 'Job Title',
          accessor: 'jobTitle',
          cellRender: ({ jobTitle }) => jobTitle,
        },
        {
          headerRender: () => 'Regional Role',
          accessor: 'regionalRole',
          cellRender: ({ regionalRole }) =>
            regionalRole ? <GreenCheckIcon /> : EMPTY_DEFAULT,
        },
      ] as TableLayout<EmployeeJobHistoryType>[],
    [
      hasJobContractHistoryDeleteAccess,
      hasJobContractHistoryEditAccess,
      onRemove,
      onUpdate,
      openDeleteRecordModal,
    ]
  );

  const tableCommonProps: TableProps<EmployeeJobHistoryType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<EmployeeJobHistoryType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
