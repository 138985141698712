import { ReadonlyDetail } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';

export const BankDetails = () => {
  const { employeeDetail } = useEmployeeDetailContext();

  return (
    <div className='mb-6'>
      <fieldset className='form-fieldset'>
        <ReadonlyDetail
          label='Bank Country'
          value={employeeDetail.personalInformation.bankCountryCode}
        />

        <ReadonlyDetail
          label='Bank Name'
          value={employeeDetail.personalInformation.bankName}
        />

        <ReadonlyDetail
          label='Branch Name'
          value={employeeDetail.personalInformation.branchName}
        />

        <ReadonlyDetail
          label='Account Number'
          value={employeeDetail.personalInformation.accountNumber}
        />

        <ReadonlyDetail
          label='Account Holder Name'
          value={employeeDetail.personalInformation.accountHolderName}
        />
      </fieldset>
    </div>
  );
};
