import {
  CardFooter,
  CardSection,
  CardTitle,
  ContractInformationFormFields,
  GeneralInformationFormFields,
  JobInformationFormFields,
} from '@/shared/components';
import { useCreateEmployee } from '@/shared/hooks';
import {
  EmployeeAddFormData,
  EmployeeAddFormDataType,
  FormAction,
} from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { FormProvider, useForm } from 'react-hook-form';
import { ExternalAccount } from '../external-account';
import { Permission } from '../permission';
import { PersonalInformation } from '../personal-information';
import { addFormValidator } from './employee-add-form.validator';

export const EmployeeAddForm = () => {
  const form = useForm<EmployeeAddFormDataType>({
    defaultValues: EmployeeAddFormData.parse({}),
    resolver: vestResolver(addFormValidator),
  });

  const { handleCreateEmployee, loading } = useCreateEmployee();

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={form.handleSubmit((formData) => {
          handleCreateEmployee(formData);
        })}
      >
        <CardSection>
          <CardTitle>General Information</CardTitle>
          <GeneralInformationFormFields mode={FormAction.CREATING} />
        </CardSection>

        <CardSection>
          <CardTitle>Contract Information</CardTitle>
          <ContractInformationFormFields mode={FormAction.CREATING} />
        </CardSection>

        <CardSection>
          <CardTitle>Job Information</CardTitle>
          <JobInformationFormFields mode={FormAction.CREATING} />
        </CardSection>

        <hr className='my-6' />

        <CardSection>
          <CardTitle>External Account</CardTitle>
          <ExternalAccount />
        </CardSection>

        <hr className='my-6' />

        <CardSection>
          <CardTitle>Permission</CardTitle>
          <span>
            If you need to create an employee with higher permission or in
            companies that you are not assigned to, contact Minnie Poolsuk or
            Shiori Matsuyama.
          </span>
          <Permission />
        </CardSection>

        <hr className='my-6' />

        <PersonalInformation />

        <CardFooter>
          <Button className='ml-auto bg-blue-6' type='submit' loading={loading}>
            Save
          </Button>
        </CardFooter>
      </form>
    </FormProvider>
  );
};
