import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListMinimalCompaniesQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  keyword: Types.InputMaybe<Types.Scalars['String']['input']>;
  enabled: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterOnlyAccessible: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ListMinimalCompaniesQuery = { __typename?: 'Query', listCompanies: { __typename?: 'ListCompanies', totalCount: number, items: Array<{ __typename?: 'Company', id: string, name: string }> } };


export const ListMinimalCompaniesDocument = gql`
    query listMinimalCompanies($pageNumber: Int!, $pageSize: Int!, $keyword: String, $enabled: Boolean, $filterOnlyAccessible: Boolean) {
  listCompanies(
    pageNumber: $pageNumber
    pageSize: $pageSize
    keyword: $keyword
    enabled: $enabled
    filterOnlyAccessible: $filterOnlyAccessible
  ) {
    items {
      id
      name
    }
    totalCount
  }
}
    `;

/**
 * __useListMinimalCompaniesQuery__
 *
 * To run a query within a React component, call `useListMinimalCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMinimalCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMinimalCompaniesQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      keyword: // value for 'keyword'
 *      enabled: // value for 'enabled'
 *      filterOnlyAccessible: // value for 'filterOnlyAccessible'
 *   },
 * });
 */
export function useListMinimalCompaniesQuery(baseOptions: Apollo.QueryHookOptions<ListMinimalCompaniesQuery, ListMinimalCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMinimalCompaniesQuery, ListMinimalCompaniesQueryVariables>(ListMinimalCompaniesDocument, options);
      }
export function useListMinimalCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMinimalCompaniesQuery, ListMinimalCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMinimalCompaniesQuery, ListMinimalCompaniesQueryVariables>(ListMinimalCompaniesDocument, options);
        }
export function useListMinimalCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListMinimalCompaniesQuery, ListMinimalCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListMinimalCompaniesQuery, ListMinimalCompaniesQueryVariables>(ListMinimalCompaniesDocument, options);
        }
export type ListMinimalCompaniesQueryHookResult = ReturnType<typeof useListMinimalCompaniesQuery>;
export type ListMinimalCompaniesLazyQueryHookResult = ReturnType<typeof useListMinimalCompaniesLazyQuery>;
export type ListMinimalCompaniesSuspenseQueryHookResult = ReturnType<typeof useListMinimalCompaniesSuspenseQuery>;
export type ListMinimalCompaniesQueryResult = Apollo.QueryResult<ListMinimalCompaniesQuery, ListMinimalCompaniesQueryVariables>;