import { Table } from '@/shared/components';
import { useMemo } from 'react';
import { useReportNumberOfEmployeeBusinessUnitQuery } from 'src/graphql/query';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilterType } from 'src/types/filters';
import { TableUtils } from '../../utils';

type ClientReportNumberOfEmployeeBusinessUnit = {
  businessUnitName: string;
};

export const NumberOfEmployeesBusinessUnitTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { data, loading } = useReportNumberOfEmployeeBusinessUnitQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = TableUtils.generateColumnNames(
    Number(filters.current.year)
  );
  const businessUnits = useMemo(
    () =>
      data?.reportNumberOfEmployeeBusinessUnit.items.map(
        (item) => item.businessUnit.name
      ),
    [data?.reportNumberOfEmployeeBusinessUnit.items]
  );

  const tableData: ClientReportNumberOfEmployeeBusinessUnit[] = useMemo(
    () =>
      [...new Set(businessUnits)].map((businessUnit: string) => ({
        businessUnitName: businessUnit,
        ...Object.fromEntries(
          (yearMonths || []).map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeBusinessUnit.items.find(
              (item) =>
                item.yearMonth === yearMonth &&
                item.businessUnit.name === businessUnit
            )?.total ?? 0,
          ])
        ),
      })),
    [businessUnits, data?.reportNumberOfEmployeeBusinessUnit.items, yearMonths]
  );

  const columns = useMemo(
    () => [
      {
        headerRender: () => <div />,
        accessor: 'businessUnit',
        tdClassName: '!bg-disabled border',
        thClassName: '!bg-disabled border',
        minWidth: '12rem',
        cellRender: (row: ClientReportNumberOfEmployeeBusinessUnit) => (
          <span className='text-2xs'> {row.businessUnitName}</span>
        ),
      },
      ...yearMonths.map((yearMonth) => ({
        headerRender: () => (
          <div className='flex h-12 w-full items-center justify-center border-r px-3 text-4xs'>
            {yearMonth}
          </div>
        ),
        accessor: yearMonth,
        thClassName: loading ? undefined : 'p-0',
        tdClassName: loading ? undefined : 'h-10 p-0',
        cellRender: (row: ClientReportNumberOfEmployeeBusinessUnit) => (
          <div className='flex h-full w-full items-center justify-end border-r px-3 text-2xs'>
            {row[
              yearMonth as keyof ClientReportNumberOfEmployeeBusinessUnit
            ].toString()}
          </div>
        ),
      })),
    ],
    [loading, yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeBusinessUnit>
        layout={columns || []}
        data={tableData || []}
        loading={loading}
      />
    </div>
  );
};
