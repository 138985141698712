import { VisaFormDataType } from '@/shared/types';
import { create, enforce, test } from 'vest';

export const visaFormValidationSuite = create(
  ({
    visaType,
    visaNumber,
    visaIssuedDate,
    visaExpiryDate,
    workPermitNumber,
    workPermitIssuedDate,
    workPermitExpiryDate,
  }: VisaFormDataType) => {
    test('allFields', 'At least one field must be filled', () => {
      const allFieldsEmpty =
        !visaType &&
        !visaNumber &&
        !visaIssuedDate &&
        !visaExpiryDate &&
        !workPermitNumber &&
        !workPermitIssuedDate &&
        !workPermitExpiryDate;
      enforce(!allFieldsEmpty).isTruthy();
    });
  }
);
