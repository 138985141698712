import {
  EMPTY_DEFAULT,
  NoResult,
  Table,
  TableLayout,
  TableProps,
} from '@/shared/components';
import { UserEmployeeType } from '@/shared/types';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface EmployeeTableProps {
  loading: boolean;
  items: UserEmployeeType[];
}

export const EmployeeTable = ({ items, loading }: EmployeeTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'legalName',
          cellRender: (userEmployee: UserEmployeeType) => {
            return (
              <Link
                reloadDocument
                to={`/employees/edit/${userEmployee.id}?tab=general-information`}
              >
                <span className='text-blue-6 hover:cursor-pointer'>
                  {userEmployee.legalName}
                </span>
              </Link>
            );
          },
        },

        {
          headerRender: () => 'Company',
          accessor: 'company',
          cellRender: (userEmployee: UserEmployeeType) => {
            return <span>{userEmployee.company || EMPTY_DEFAULT}</span>;
          },
        },
        {
          headerRender: () => 'Business Unit',
          accessor: 'businessUnit',
          cellRender: (userEmployee: UserEmployeeType) => {
            return <span>{userEmployee.businessUnit || EMPTY_DEFAULT}</span>;
          },
        },
        {
          headerRender: () => 'Department',
          accessor: 'department',
          cellRender: (userEmployee: UserEmployeeType) => {
            return <span>{userEmployee.department || EMPTY_DEFAULT}</span>;
          },
        },
      ] as TableLayout<UserEmployeeType>[],
    []
  );

  const tableCommonProps: TableProps<UserEmployeeType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<UserEmployeeType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
