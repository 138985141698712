import {
  ContractText,
  EMPTY_DEFAULT,
  JobHistoryLevelLabels,
  NoResult,
  Table,
  TableLayout,
} from '@/shared/components';
import { TableSelection } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FilterProps } from 'src/hooks/shared';
import {
  PerformanceReviewResultList,
  PerformanceReviewResultListItem,
} from '../../types';
import { PerformanceReviewResultListExport } from '../performance-review-result-list-export';
import { PerformanceReviewResultListFilterType } from '../performance-review-result-list-filter/PerformanceReviewResultList.filters';

interface Props {
  data: PerformanceReviewResultList;
  loading: boolean;
  page: number;
  filters: FilterProps<PerformanceReviewResultListFilterType>;
}

export const PerformanceReviewResultListTable = ({
  data,
  loading,
  page,
  filters,
}: Props) => {
  const [selection, setSelection] = useState<
    TableSelection<PerformanceReviewResultListItem>
  >({
    items: [],
    allPages: false,
  });

  const layout: TableLayout<PerformanceReviewResultListItem>[] = useMemo(
    () => [
      {
        headerRender: () => 'Employee Name',
        accessor: 'legalFullName',
        truncated: true,
        sticky: true,
        cellRender: ({ employee, performanceReviewId }) => (
          <Link
            className='text-blue-6 hover:cursor-pointer'
            to={`/performance-review/edit/${performanceReviewId}?tab=overview`}
          >
            {employee.generalInformation.legalFullName}
          </Link>
        ),
      },
      {
        headerRender: () => 'Numeric Target',
        accessor: 'numericTarget',
        cellRender: ({ performanceTarget }) =>
          `${performanceTarget.numeric.score}%`,
      },
      {
        headerRender: () => 'Impact-focused',
        accessor: 'impactFocusedTarget',
        cellRender: ({ performanceTarget }) =>
          `${performanceTarget.impactFocused.score}%`,
      },
      {
        headerRender: () => 'Performance Target',
        accessor: 'performanceTarget',
        cellRender: ({ performanceTarget }) =>
          `${performanceTarget.all.score}%`,
      },
      {
        headerRender: () => 'Value',
        accessor: 'value',
        cellRender: ({ value }) => value.score,
      },
      {
        headerRender: () => 'Growth Target',
        accessor: 'growthTarget',
        cellRender: ({ growthTarget }) => growthTarget,
      },
      {
        headerRender: () => 'Contract Type',
        accessor: 'contractType',
        cellRender: ({ employee }) => {
          return (
            <span>
              {employee.currentContract?.contractType
                ? String(ContractText[employee.currentContract?.contractType])
                : EMPTY_DEFAULT}
            </span>
          );
        },
      },
      {
        headerRender: () => 'Company',
        accessor: 'company',
        cellRender: ({ employee: { currentContract } }) =>
          StringUtils.withFallback(currentContract?.company.name),
      },
      {
        headerRender: () => 'Office Location',
        accessor: 'officeLocation',
        cellRender: ({ employee: { currentPosition } }) =>
          StringUtils.withFallback(currentPosition?.location.name),
      },
      {
        headerRender: () => 'Business Unit',
        accessor: 'businessUnit',
        cellRender: ({ employee: { currentPosition } }) =>
          StringUtils.withFallback(currentPosition?.businessUnit.name),
      },
      {
        headerRender: () => 'Department',
        accessor: 'department',
        cellRender: ({ employee: { currentPosition } }) =>
          StringUtils.withFallback(currentPosition?.department.name),
      },
      {
        headerRender: () => 'Job Title',
        accessor: 'jobTitle',
        cellRender: ({ employee: { currentPosition } }) =>
          StringUtils.withFallback(currentPosition?.jobTitle),
      },
      {
        headerRender: () => 'Level',
        accessor: 'level',
        cellRender: ({ employee: { currentPosition } }) =>
          currentPosition?.level
            ? JobHistoryLevelLabels[currentPosition.level]
            : EMPTY_DEFAULT,
      },
      {
        headerRender: () => 'Total Target Weight',
        accessor: 'totalTargetWeight',
        cellRender: ({ performanceTarget }) =>
          `${performanceTarget.all.weight}%`,
      },
      {
        headerRender: () => 'Probation End Date',
        accessor: 'probationEndDate',
        cellRender: ({ employee: { probationReview } }) => {
          const probationEndDate =
            probationReview?.stages.slice(-1)[0]?.deadline;
          return DateUtils.formatDate(probationEndDate, 'MMM dd, yyyy');
        },
      },
    ],
    []
  );

  return (
    <Table<PerformanceReviewResultListItem>
      page={page}
      layout={layout}
      loading={loading}
      data={data || []}
      notFoundTemplate={
        <NoResult
          title='No Data Found'
          description='Try changing the filters or search terms'
        />
      }
      allowFloatingHeader
      selection={selection}
      onSelectionChange={setSelection}
      selectable={{
        byField: 'performanceReviewId',
        total: data?.length || 0,
        aside: (
          <PerformanceReviewResultListExport
            selection={selection}
            filters={filters}
          />
        ),
      }}
    />
  );
};
