import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListEmployeesQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter: Types.InputMaybe<Types.ListEmployeesFilterInput>;
  sort: Types.InputMaybe<Types.ListEmployeesSortInput>;
}>;


export type ListEmployeesQuery = { __typename?: 'Query', listEmployees: { __typename?: 'ListEmployees', totalCount: number, items: Array<{ __typename?: 'Employee', id: string, status: Types.EmployeeStatus, generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string, legalFullName: string, hiredDate: any, companyEmail: { __typename?: 'EmployeeCompanyEmail', fullAddress: string } | null, primaryManager: { __typename?: 'Employee', id: string, generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string } } | null, dottedLineManager: { __typename?: 'Employee', id: string, generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string } } | null, nationality: { __typename?: 'Country', name: string } | null }, externalAccounts: Array<{ __typename?: 'EmployeeExternalAccount', accountType: Types.EmployeeExternalAccountType, status: Types.EmployeeExternalAccountStatus }>, currentContract: { __typename?: 'EmployeeContract', contractType: Types.ContractType, company: { __typename?: 'Company', name: string } } | null, currentPosition: { __typename?: 'EmployeeJobHistory', jobTitle: string, level: Types.JobHistoryLevel, businessUnit: { __typename?: 'BusinessUnit', name: string }, location: { __typename?: 'Location', name: string }, department: { __typename?: 'Department', name: string }, job: { __typename?: 'Job', name: string } } | null, offBoarding: { __typename?: 'EmployeeOffBoarding', lastWorkingDate: any | null, employmentEndDate: any | null, reasonForLeaving: { __typename?: 'ReasonForLeaving', name: string } | null } | null }> } };


export const ListEmployeesDocument = gql`
    query listEmployees($pageNumber: Int!, $pageSize: Int!, $filter: ListEmployeesFilterInput, $sort: ListEmployeesSortInput) {
  listEmployees(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
    sort: $sort
  ) {
    items {
      id
      status
      generalInformation {
        preferredFullName
        legalFullName
        companyEmail {
          fullAddress
        }
        hiredDate
        primaryManager {
          id
          generalInformation {
            preferredFullName
          }
        }
        dottedLineManager {
          id
          generalInformation {
            preferredFullName
          }
        }
        nationality {
          name
        }
      }
      externalAccounts {
        accountType
        status
      }
      currentContract {
        contractType
        company {
          name
        }
      }
      currentPosition {
        businessUnit {
          name
        }
        location {
          name
        }
        department {
          name
        }
        job {
          name
        }
        jobTitle
        level
      }
      offBoarding {
        lastWorkingDate
        employmentEndDate
        reasonForLeaving {
          name
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useListEmployeesQuery__
 *
 * To run a query within a React component, call `useListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
      }
export function useListEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export function useListEmployeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export type ListEmployeesQueryHookResult = ReturnType<typeof useListEmployeesQuery>;
export type ListEmployeesLazyQueryHookResult = ReturnType<typeof useListEmployeesLazyQuery>;
export type ListEmployeesSuspenseQueryHookResult = ReturnType<typeof useListEmployeesSuspenseQuery>;
export type ListEmployeesQueryResult = Apollo.QueryResult<ListEmployeesQuery, ListEmployeesQueryVariables>;