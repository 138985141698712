import { EmployeeGeneralInformationFormDataType } from '@/shared/types';
import { create, enforce, test } from 'vest';

export const generalInformationFormValidator = create(
  ({
    preferredFullName,
    legalFullName,
    nationalityId,
    primaryManagerId,
    emailDomain,
    emailUsername,
    hiringSource,
  }: EmployeeGeneralInformationFormDataType) => {
    test(
      'preferredFullName',
      'Preferred Full Name in English is required',
      () => {
        enforce(preferredFullName).isNotEmpty();
      }
    );
    test('legalFullName', 'Legal Full Name in English is required', () => {
      enforce(legalFullName).isNotEmpty();
    });
    test('nationalityId', 'Nationality is required', () => {
      enforce(nationalityId).isNotEmpty();
    });
    test('primaryManagerId', 'Primary Manager is required', () => {
      enforce(primaryManagerId).isNotEmpty();
    });
    test('emailDomain', 'Email Username is required', () => {
      enforce(emailDomain).isNotEmpty();
    });
    test('emailUsername', 'Email Domain is required', () => {
      enforce(emailUsername).isNotEmpty();
    });
  }
);
