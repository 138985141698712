import { CardSection, ContractText, ReadonlyDetail } from '@/shared/components';
import { EmployeeDetailType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';

export const CurrentContract = ({
  employee,
  hasJobContractHistoryViewAccess,
}: {
  employee: EmployeeDetailType;
  hasJobContractHistoryViewAccess: boolean;
}) => {
  const employeeGeneralInformation = employee.generalInformation;

  return (
    <CardSection className='mt-10'>
      <div className='mb-6'>
        <h1 className='text-lg font-semibold'>Current Contract</h1>
        {hasJobContractHistoryViewAccess && (
          <p className='text-[0.75rem] text-gray-7'>
            You can see and edit contract histories in "Contract / Job History
            tab"
          </p>
        )}
      </div>
      <div className='form-fieldset gap-6'>
        <ReadonlyDetail
          label='Contract Type'
          value={
            employeeGeneralInformation.contractType
              ? ContractText[employeeGeneralInformation.contractType]
              : null
          }
        />

        <ReadonlyDetail
          label='Employee Code'
          value={employeeGeneralInformation.employeeCode}
        />

        <ReadonlyDetail
          label='Company'
          value={employeeGeneralInformation.company}
        />

        <ReadonlyDetail
          label='Contract End Date'
          value={
            employeeGeneralInformation.effectiveDateUntil
              ? DateUtils.formatDate(
                  new Date(
                    employeeGeneralInformation.effectiveDateUntil || ''
                  ).toISOString(),
                  'yyyy-MM-dd'
                )
              : null
          }
        />
      </div>
    </CardSection>
  );
};
