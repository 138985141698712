import { z } from 'zod';

export const EmployeeVisa = z.object({
  id: z.string().default(''),
  visaType: z.string().nullable().default(null),
  visaNumber: z.string().nullable().default(null),
  visaIssuedPlace: z.string().nullable().default(null),
  visaIssuedDate: z.string().nullable().default(null),
  visaExpiryDate: z.string().nullable().default(null),
  workPermitNumber: z.string().nullable().default(null),
  workPermitIssuedDate: z.string().nullable().default(null),
  workPermitExpiryDate: z.string().nullable().default(null),
});
export type EmployeeVisaType = z.infer<typeof EmployeeVisa>;
