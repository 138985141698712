import { ReadonlyDetail } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { DateUtils } from '@/shared/utils';

export const IdentificationDetails = () => {
  const { employeeDetail } = useEmployeeDetailContext();

  return (
    <div className='mb-6'>
      <fieldset className='form-fieldset'>
        <ReadonlyDetail
          label='ID Type'
          value={employeeDetail.personalInformation.idType}
        />

        <ReadonlyDetail
          label='ID Number'
          value={employeeDetail.personalInformation.idNumber}
        />

        <ReadonlyDetail
          label='ID Issued Place'
          value={employeeDetail.personalInformation.idIssuedPlace}
        />

        <ReadonlyDetail
          label='ID Issued Date'
          value={
            employeeDetail.personalInformation.idIssuedDate
              ? DateUtils.formatDate(
                  new Date(
                    employeeDetail.personalInformation.idIssuedDate || ''
                  ).toISOString(),
                  'yyyy-MM-dd'
                )
              : null
          }
        />

        <ReadonlyDetail
          label='ID Expiry Date'
          value={
            employeeDetail.personalInformation.idExpiryDate
              ? DateUtils.formatDate(
                  new Date(
                    employeeDetail.personalInformation.idExpiryDate
                  ).toISOString(),
                  'yyyy-MM-dd'
                )
              : null
          }
        />
      </fieldset>
    </div>
  );
};
