import { Card, PageTitle } from '@/shared/components';
import { useNavigate } from 'react-router-dom';
import { EmployeeListImportModal } from '../list/components';
import { EmployeeAddForm } from './components';

export const EmployeeAdd = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle text='Add Employee' onBack={() => navigate('/employees')} />
      <Card>
        <div className='mb-6 flex space-x-1'>
          <span>If you have multiple records to add, use</span>
          <EmployeeListImportModal>
            <span className='cursor-pointer text-blue-6 hover:underline'>
              spreadsheet import
            </span>
            .
          </EmployeeListImportModal>
        </div>
        <EmployeeAddForm />
      </Card>
    </>
  );
};
