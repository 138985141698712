import { Table } from '@/shared/components';
import { useMemo } from 'react';
import { useReportNumberOfEmployeeCompanyQuery } from 'src/graphql/query';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilterType } from 'src/types/filters';
import { TableUtils } from '../../utils';

type ClientReportNumberOfEmployeeCompany = {
  companyName: string;
};

export const NumberOfEmployeesCompanyTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { data, loading } = useReportNumberOfEmployeeCompanyQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = TableUtils.generateColumnNames(
    Number(filters.current.year)
  );

  const companies = useMemo(
    () =>
      (data?.reportNumberOfEmployeeCompany.items || []).map(
        (item) => item.company.name
      ),
    [data?.reportNumberOfEmployeeCompany.items]
  );

  const tableData: ClientReportNumberOfEmployeeCompany[] = useMemo(
    () =>
      [...new Set(companies)].map((company: string) => {
        const yearMonthData = Object.fromEntries(
          yearMonths.map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeCompany.items.find(
              (item) =>
                item.yearMonth === yearMonth && item.company.name === company
            )?.total ?? 0,
          ])
        );

        return {
          companyName: company,
          ...yearMonthData,
        };
      }),
    [companies, data?.reportNumberOfEmployeeCompany.items, yearMonths]
  );

  const columns = useMemo(
    () => [
      {
        headerRender: () => <div />,
        accessor: 'company',
        tdClassName: '!bg-disabled border',
        thClassName: '!bg-disabled border',
        minWidth: '12rem',
        cellRender: (row: ClientReportNumberOfEmployeeCompany) => (
          <span className='text-2xs'> {row.companyName}</span>
        ),
      },
      ...yearMonths.map((yearMonth) => ({
        headerRender: () => (
          <div className='flex h-12 w-full items-center justify-center border-r px-3 text-4xs'>
            {yearMonth}
          </div>
        ),
        accessor: yearMonth,
        thClassName: loading ? undefined : 'p-0',
        tdClassName: loading ? undefined : 'h-10 p-0',
        cellRender: (row: ClientReportNumberOfEmployeeCompany) => (
          <div className='flex h-full w-full items-center justify-end border-r px-3 text-2xs'>
            {row[
              yearMonth as keyof ClientReportNumberOfEmployeeCompany
            ].toString()}
          </div>
        ),
      })),
    ],
    [loading, yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeCompany>
        layout={columns || []}
        data={tableData || []}
        loading={loading}
      />
    </div>
  );
};
