import { NumberUtils } from '@/shared/utils';
import classNames from 'classnames';
import { EMPTY_DEFAULT } from '../table';

interface NumberWithFormatProps {
  amount?: number | string | null;
  option?: Intl.NumberFormatOptions;
  className?: string;
}

export const NumberWithFormat = ({
  amount,
  option,
  className,
}: NumberWithFormatProps) => {
  if (
    typeof amount === 'bigint' ||
    typeof amount === 'number' ||
    typeof amount === 'string'
  ) {
    return (
      <span className={classNames(className, 'tabular-nums')}>
        {NumberUtils.format(amount, option)}
      </span>
    );
  }

  return <>{EMPTY_DEFAULT}</>;
};
