import { EmployeeImportOutput } from '@/shared/graphql';
import { Accordion } from '@any-ui-react/core';
import { useMemo } from 'react';
import { AlertBanner } from 'src/components/shared';
import { ImportErrors, ImportErrorsItem } from '../../types';

interface Props {
  outputs?: EmployeeImportOutput[];
  showSheet?: boolean;
}

export const EmployeeListImportErrors = ({ outputs, showSheet }: Props) => {
  const groupedErrors = useMemo(() => {
    if (!outputs) {
      return;
    }

    return outputs.reduce(
      (
        errorsAcc: { [key: string]: { [key: string]: ImportErrors } },
        sheetErrors
      ) => {
        errorsAcc[sheetErrors.sheetName] = sheetErrors.errors.reduce(
          (acc: { [key: string]: ImportErrors }, e: ImportErrorsItem) => {
            if (!e) {
              return acc;
            }

            if (e.__typename === 'EmployeeImportOutputErrorOther') {
              acc['others'] = acc['others'] || [];
              acc['others'].push(e);
            }

            if (e.__typename === 'EmployeeImportOutputErrorRow') {
              const rowNumber = e.rowNumber;
              acc[rowNumber] = acc[rowNumber] || [];
              (acc[rowNumber] = acc[rowNumber] || []).push(e);
            }

            return acc;
          },
          {}
        );

        return errorsAcc;
      },
      {}
    );
  }, [outputs]);

  if (!groupedErrors || !Object.keys(groupedErrors).length) {
    return null;
  }

  return (
    <AlertBanner
      title='Error importing the spreadsheet'
      type='warning'
      classNames={{
        root: 'my-4 shadow-banner',
      }}
    >
      {Object.entries(groupedErrors).map(([sheetName, errors]) => {
        return (
          <>
            {showSheet && (
              <div className='mt-2 text-sm font-semibold'>
                Sheet: {sheetName}
              </div>
            )}

            <Accordion chevronPosition='left'>
              <ul>
                {Object.entries(errors).map(([key, value]) => {
                  if (key === 'others') {
                    return (
                      <li key={key}>
                        <Accordion.Item
                          value={key}
                          className='border-none text-xs'
                        >
                          <Accordion.Control
                            className='h-6 text-xs'
                            style={{ padding: 0 }}
                          >
                            Others
                          </Accordion.Control>
                          <Accordion.Panel>
                            <ul>
                              {value.map((e) => (
                                <li key={e.message}>{e.message}</li>
                              ))}
                            </ul>
                          </Accordion.Panel>
                        </Accordion.Item>
                      </li>
                    );
                  }

                  return (
                    <li key={key}>
                      <Accordion.Item
                        value={key}
                        className='border-none text-xs'
                      >
                        <Accordion.Control
                          className='h-6 text-xs'
                          style={{ padding: 0 }}
                        >
                          <div>Row {key}</div>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <ul>
                            {value.map((e) => {
                              if (
                                e.__typename === 'EmployeeImportOutputErrorRow'
                              ) {
                                <span className='bg-red-100'>{e.message}</span>;
                                return (
                                  <li key={e.message}>
                                    {e.columnName && (
                                      <span className='font-semibold'>
                                        {e.columnName}:{' '}
                                      </span>
                                    )}
                                    {e.message}
                                  </li>
                                );
                              }

                              return null;
                            })}
                          </ul>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </li>
                  );
                })}
              </ul>
            </Accordion>
          </>
        );
      })}
    </AlertBanner>
  );
};
