import {
  ContractText,
  EMPTY_DEFAULT,
  JobHistoryLevelLabels,
  NoResult,
  Table,
  TableLayout,
} from '@/shared/components';
import { DateUtils, StringUtils } from '@/shared/utils';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PerformanceReviewStatusBadge } from '../../../components';
import { PerformanceReviewList, PerformanceReviewListItem } from '../../types';

interface Props {
  data: PerformanceReviewList;
  loading: boolean;
  page: number;
}

export const PerformanceReviewListTable = ({ data, loading, page }: Props) => {
  const layout: TableLayout<PerformanceReviewListItem>[] = useMemo(
    () => [
      {
        headerRender: () => 'Preferred Full Name',
        accessor: 'preferredFullName',
        truncated: true,
        sticky: true,
        cellRender: ({ id, employee }) => {
          return (
            <Link to={`/performance-review/edit/${id}`}>
              <span className='text-blue-6 hover:cursor-pointer'>
                {employee.generalInformation.preferredFullName}
              </span>
            </Link>
          );
        },
      },
      {
        headerRender: () => 'Performance Review Status',
        accessor: 'performanceReviewStatus',
        cellRender: ({ status }) => (
          <PerformanceReviewStatusBadge status={status} />
        ),
      },
      {
        headerRender: () => 'Contract Type',
        accessor: 'contractType',
        cellRender: ({ employee }) => {
          return (
            <span>
              {employee.currentContract?.contractType
                ? String(ContractText[employee.currentContract?.contractType])
                : EMPTY_DEFAULT}
            </span>
          );
        },
      },
      {
        headerRender: () => 'Total target weight',
        accessor: 'totalTargetWeight',
        cellRender: ({ totalTargetWeight }) => {
          return (
            <span className='block text-center'>{totalTargetWeight}%</span>
          );
        },
      },
      {
        headerRender: () => 'Probation End Date',
        accessor: 'probationEndDate',
        cellRender: ({ employee: { probationReview } }) => {
          const probationEndDate =
            probationReview?.stages.slice(-1)[0]?.deadline;
          return DateUtils.formatDate(probationEndDate, 'MMM dd, yyyy');
        },
      },
      {
        headerRender: () => 'Level',
        accessor: 'level',
        cellRender: ({ employee: { currentPosition } }) =>
          currentPosition?.level
            ? JobHistoryLevelLabels[currentPosition.level]
            : EMPTY_DEFAULT,
      },
      {
        headerRender: () => 'Company',
        accessor: 'company',
        cellRender: ({ employee: { currentContract } }) =>
          StringUtils.withFallback(currentContract?.company.name),
      },
      {
        headerRender: () => 'Office Location',
        accessor: 'officeLocation',
        cellRender: ({ employee: { currentPosition } }) =>
          StringUtils.withFallback(currentPosition?.location.name),
      },
      {
        headerRender: () => 'Business Unit',
        accessor: 'businessUnit',
        cellRender: ({ employee: { currentPosition } }) =>
          StringUtils.withFallback(currentPosition?.businessUnit.name),
      },
      {
        headerRender: () => 'Department',
        accessor: 'department',
        cellRender: ({ employee: { currentPosition } }) =>
          StringUtils.withFallback(currentPosition?.department.name),
      },
      {
        headerRender: () => 'Job Title',
        accessor: 'jobTitle',
        cellRender: ({ employee: { currentPosition } }) =>
          StringUtils.withFallback(currentPosition?.jobTitle),
      },
    ],
    []
  );

  return (
    <Table<PerformanceReviewListItem>
      page={page}
      layout={layout}
      loading={loading}
      data={data || []}
      notFoundTemplate={
        <NoResult
          title='No Data Found'
          description='Try changing the filters or search terms'
        />
      }
      // allowFloatingHeader
      // selection={selection}
      // onSelectionChange={setSelection}
      // selectable={{
      //   byField: 'id',
      //   total: data?.length || 0,
      //   aside: <Button className='bg-blue-6'>Export</Button>,
      // }}
    />
  );
};
