import { Card, DEFAULT_PAGE_SIZE, PageTitle } from '@/shared/components';
import { useProbationReviewsQuery } from '@/shared/graphql';
import { useFilters } from '@/shared/hooks';
import {
  ProbationReview,
  ProbationReviewFilter as ProbationReviewFilterParser,
  ProbationReviewFilterType,
} from '@/shared/types';
import { TablePagination } from '@any-ui-react/core';
import { useWindowScroll } from '@mantine/hooks';
import { ProbationReviewFilters, ProbationReviewTable } from './components';

export const ProbationReviewList = () => {
  const [, scrollTo] = useWindowScroll();

  const filters = useFilters<ProbationReviewFilterType>(
    {
      keyword: '',
      page: 1,
      companyIds: [],
      businessUnitIds: [],
      levels: [],
      reviewerIds: [],
      primaryManagerIds: [],
      isOverDue: false,
    },
    {
      parser: ProbationReviewFilterParser.parse,
    }
  );
  const { page, ...currentFilter } = filters.current;

  const { data: probationReviewList, loading } = useProbationReviewsQuery({
    variables: {
      pageNumber: page,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: {
        businessUnitIds: currentFilter.businessUnitIds,
        companyIds: currentFilter.companyIds,
        keyword: currentFilter.keyword,
        levels: currentFilter.levels,
        primaryManagerIds: currentFilter.primaryManagerIds,
        reviewerIds: currentFilter.reviewerIds,
        isOverdueOnly: currentFilter.isOverDue,
      },
    },
  });

  return (
    <>
      <PageTitle text='Probation Review' />
      <Card withPadding={false}>
        <ProbationReviewFilters filters={filters} />
        <ProbationReviewTable
          items={
            probationReviewList?.probationReviews.items.map((item) =>
              ProbationReview.parse({
                employeeId: item.employeeId,
                employeeName:
                  item.employee.generalInformation.preferredFullName,
                hiredDate: item.employee.generalInformation.hiredDate,
                companyName: item.employee.currentContract?.company.name,
                officeLocation: item.employee.currentPosition?.location.name,
                businessUnit: item.employee.currentPosition?.businessUnit.name,
                level: item.employee.currentPosition?.level,
                primaryManager:
                  item.employee.generalInformation.primaryManager
                    ?.generalInformation.preferredFullName,
                primaryManagerId:
                  item.employee.generalInformation.primaryManager?.id,
                reviewDates: item.stages.map((stage) => stage.deadline),
                status: item.status,
              })
            ) ?? []
          }
          loading={loading}
        />
      </Card>
      <TablePagination
        className='px-4 py-3 lg:px-0'
        value={page}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        withItems={true}
        count={probationReviewList?.probationReviews.totalCount || 0}
        onChange={(newPage) => {
          filters.changeFilter('page', newPage, false);
          scrollTo({ y: 0 });
        }}
      />
    </>
  );
};
