import { AuthUser } from '../types/auth/AuthUser';

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const AUTHORIZATION_KEY = 'authorization';

export const getAuthUserInSession = () => {
  const rawToken = Cookies.get(AUTHORIZATION_KEY);
  if (!rawToken) {
    return AuthUser.parse({});
  }

  try {
    const decoded = jwtDecode(rawToken);
    return AuthUser.parse(decoded);
  } catch (e) {
    console.error(e);
    return AuthUser.parse({});
  }
};

export const setAuthUserInSession = (rawToken: string) => {
  try {
    // const authUser = getAuthUserInSession();
    // if (authUser.name) {
    //   return authUser;
    // }

    const decoded = jwtDecode(rawToken);
    const expirationMs = decoded.exp ?? 0;

    Cookies.set(AUTHORIZATION_KEY, rawToken, {
      expires: expirationMs * 1000,
    });

    return AuthUser.parse(decoded);
  } catch (e) {
    console.error(e);
    console.error('The invalid token is', [rawToken]);
    return AuthUser.parse({});
  }
};

export const removeAuthUserInSession = () => {
  Cookies.remove(AUTHORIZATION_KEY);
  return AuthUser.parse({});
};

export const getAuthToken = () => {
  return Cookies.get(AUTHORIZATION_KEY);
};
