import { Table } from '@/shared/components';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useReportNumberOfEmployeeLocationQuery } from 'src/graphql/query';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilterType } from 'src/types/filters';
import { TableUtils } from '../../utils';

type ClientReportNumberOfEmployeeCompany = {
  id: string;
  name: string;
  isCountry: boolean;
};

export const NumberOfEmployeesOfficeLocationTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { data, loading } = useReportNumberOfEmployeeLocationQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = TableUtils.generateColumnNames(
    Number(filters.current.year)
  );

  const countries = useMemo(
    () =>
      data?.reportNumberOfEmployeeLocation.items
        .map((item) => ({
          name: item.location.country?.name,
          id: item.location.country?.id,
        }))
        .filter((country, index, self) => {
          const firstIndex = self.findIndex((item) => item?.id === country?.id);
          return firstIndex === index;
        }),
    [data?.reportNumberOfEmployeeLocation.items]
  );

  const items: ClientReportNumberOfEmployeeCompany[] = useMemo(() => {
    let tableData: ClientReportNumberOfEmployeeCompany[] = [];

    countries?.forEach((country) => {
      const locations = data?.reportNumberOfEmployeeLocation.items
        .filter((item) => item.location.country?.id === country?.id)
        .map((item) => ({
          name: item.location.name,
          id: item.locationId,
        }))
        .filter((location, index, self) => {
          const firstIndex = self.findIndex((item) => item.id === location.id);
          return firstIndex === index;
        });

      tableData.push({
        id: country?.id || 'Remote',
        name: country?.name || 'Remote',
        isCountry: true,
        ...Object.fromEntries(
          yearMonths.map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeLocation.items
              .filter(
                (item) =>
                  item.yearMonth === yearMonth &&
                  item.location.country?.id === country?.id
              )
              .reduce((acc, item) => acc + item.total, 0) || 0,
          ])
        ),
      });

      locations?.forEach((location) => {
        tableData.push({
          id: location.id || '',
          name: location.name || '',
          isCountry: false,
          ...Object.fromEntries(
            yearMonths.map((yearMonth) => [
              yearMonth,
              data?.reportNumberOfEmployeeLocation.items.find(
                (item) =>
                  item.yearMonth === yearMonth &&
                  item.locationId === location.id
              )?.total ?? 0,
            ])
          ),
        });
      });
    });

    return tableData;
  }, [countries, data?.reportNumberOfEmployeeLocation.items, yearMonths]);

  const columns = useMemo(
    () => [
      {
        headerRender: () => <div>Country</div>,
        accessor: 'country',
        tdClassName: '!bg-disabled border',
        thClassName: '!bg-disabled border',
        minWidth: '12rem',
        cellRender: (row: ClientReportNumberOfEmployeeCompany) => (
          <span className='text-2xs'>
            {row.isCountry ? (
              row.name
            ) : (
              <li
                className={classNames('list-disc', {
                  'pl-6': !row.isCountry,
                })}
              >
                {row.name}
              </li>
            )}
          </span>
        ),
      },
      ...yearMonths.map((yearMonth) => ({
        headerRender: () => (
          <div className='flex h-12 w-full items-center justify-center border-r px-3 text-4xs'>
            {yearMonth}
          </div>
        ),
        accessor: yearMonth,
        thClassName: loading ? undefined : 'p-0',
        tdClassName: loading ? undefined : 'h-10 p-0',
        cellRender: (row: ClientReportNumberOfEmployeeCompany) => (
          <div className='flex h-full w-full items-center justify-end border-r px-3 text-2xs'>
            {row[
              yearMonth as keyof ClientReportNumberOfEmployeeCompany
            ].toString()}
          </div>
        ),
      })),
    ],
    [loading, yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeCompany>
        layout={columns || []}
        data={items || []}
        loading={loading}
      />
    </div>
  );
};
