import { useMemo } from 'react';

import { CompanyType, TableSelection } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { GreenCheckIcon, RedCrossIcon } from '../../../shared/icons';
import { NoResult } from '../../../shared/no-result';
import { Table, TableLayout, TableProps } from '../../../shared/table';
import { CompanyModal } from '../company-modal/CompanyModal';

interface CompanyTableProps {
  loading: boolean;
  items: CompanyType[];
  selection: TableSelection<CompanyType>;
  setSelection: (selection: TableSelection<CompanyType>) => void;
  total: number;
}

export const CompanyTable = ({
  items,
  loading,
  selection,
}: CompanyTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          minWidth: '15rem',
          cellRender: (company: CompanyType) => {
            return (
              <CompanyModal company={company}>
                <span className='text-wrap text-link hover:cursor-pointer'>
                  {company.name}
                </span>
              </CompanyModal>
            );
          },
        },
        {
          headerRender: () => 'Country',
          accessor: 'country',
          cellRender: ({ country }: CompanyType) => {
            return <span>{country.name}</span>;
          },
        },
        {
          headerRender: () => 'Single Probation Review Duration',
          accessor: 'defaultProbationPeriod',
          cellRender: ({ probationReviewTerm }: CompanyType) => (
            <span>
              {probationReviewTerm.length}{' '}
              {StringUtils.pluralize(
                probationReviewTerm.length,
                probationReviewTerm.mode.toLowerCase()
              )}
            </span>
          ),
        },
        {
          headerRender: () => 'Number of Probation Review',
          accessor: 'defaultProbationDateInterval',
          cellRender: ({ probationReviewTimes }: CompanyType) => {
            return (
              <span>
                {probationReviewTimes}{' '}
                {StringUtils.pluralize(probationReviewTimes, 'time')}
              </span>
            );
          },
        },
        {
          headerRender: () => 'Enabled',
          accessor: 'enabled',
          cellRender: ({ enabled }: CompanyType) => {
            return (
              <span>{enabled ? <GreenCheckIcon /> : <RedCrossIcon />}</span>
            );
          },
        },
        {
          headerRender: () => 'Created Date',
          accessor: 'createdDate',
          cellRender: ({ createdAt }: CompanyType) => {
            return <span>{DateUtils.formatDatetime(createdAt)}</span>;
          },
        },
        {
          headerRender: () => 'Updated Date',
          accessor: 'updatedAt',
          cellRender: ({ updatedAt }: CompanyType) => {
            return <span>{DateUtils.formatDatetime(updatedAt)}</span>;
          },
        },
      ] as TableLayout<CompanyType>[],
    []
  );

  const tableCommonProps: TableProps<CompanyType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title='No Data Found'
        description='Try changing the filters or search terms'
      />
    ),
  };

  return (
    <>
      <Table<CompanyType>
        {...tableCommonProps}
        selection={selection}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
