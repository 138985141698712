import {
  LockIcon,
  NoResult,
  Table,
  TableLayout,
  TableProps,
  TrashIcon,
} from '@/shared/components';
import { useAuthContext } from '@/shared/contexts';
import { EmployeeRole } from '@/shared/graphql';
import { DateUtils } from '@/shared/utils';
import { ActionIcon } from '@any-ui-react/core';
import { useMemo } from 'react';
import { EmployeeCustomFilterType } from './EmployeeCustomFilters.filters';

interface CustomFilterTableProps {
  loading: boolean;
  items: EmployeeCustomFilterType[];
  onRemove: (id: string) => void;
}

export const CustomFilterTable = ({
  items,
  loading,
  onRemove,
}: CustomFilterTableProps) => {
  const { authUser } = useAuthContext();
  const isSuperAdmin = authUser.role === EmployeeRole.SuperAdmin;

  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (item: EmployeeCustomFilterType) => {
            return (
              <div className='flex'>
                {!item.isOpenFilter && (
                  <LockIcon
                    className='mr-1 self-center'
                    width={14}
                    height={14}
                  />
                )}
                <span>{item.name}</span>
              </div>
            );
          },
        },
        {
          headerRender: () => 'Created By',
          accessor: 'createdBy',
          cellRender: (item: EmployeeCustomFilterType) => {
            return item.createdBy;
          },
        },
        {
          headerRender: () => 'Created At',
          accessor: 'createdAt',
          cellRender: (item: EmployeeCustomFilterType) => {
            return <span>{DateUtils.formatDatetime(item.createdAt)}</span>;
          },
        },
        {
          headerRender: () => '',
          accessor: 'delete',
          cellRender: (item: EmployeeCustomFilterType) => {
            if (item.isOpenFilter && !isSuperAdmin) {
              return <div />;
            }

            return (
              <ActionIcon
                size='xs'
                onClick={() => {
                  onRemove(item.id);
                }}
                className='ml-auto'
              >
                <TrashIcon />
              </ActionIcon>
            );
          },
        },
      ] as TableLayout<EmployeeCustomFilterType>[],
    [isSuperAdmin, onRemove]
  );

  const tableCommonProps: TableProps<EmployeeCustomFilterType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <Table<EmployeeCustomFilterType>
      {...tableCommonProps}
      page={1}
      allowFloatingHeader
      rowClassName='h-12'
    />
  );
};
