import { EmployeeImportFormat, EmployeeImportOutput } from '@/shared/graphql';
import { getStorageItem, RouterUtils } from '@/shared/utils';
import { Button, ModalFooter, Radio, TextInput } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'src/components/shared';
import { AUTH_STATE_KEY } from 'src/pages/login';
import { ApiUtils } from 'src/utils/api.utils';
import {
  useGenerateGoogleOfflineOAuthUrlLazyQuery,
  useImportEmployeesMutation,
  useVerifyGoogleOfflineTokenMutation,
} from '../../graphql';
import { EmployeeListImportErrors } from './EmployeeListImportErrors';
import { EmployeeListImportTemplate } from './EmployeeListImportTemplate';
import { importValidationSuite } from './import.validator';

export interface EmployeeListImportFormData {
  action: EmployeeImportFormat;
  link: string;
}

interface Props {
  onClose: () => void;
}

export const EmployeeListImportModalForm = ({ onClose }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<EmployeeListImportFormData>({
    resolver: vestResolver(importValidationSuite),
    defaultValues: {
      action: EmployeeImportFormat.Create,
      link: '',
    },
  });

  const action = watch('action');
  const [errorOutputs, setErrorOutputs] = useState<EmployeeImportOutput[]>();
  const [loading, setLoading] = useState(false);

  const [importEmployees] = useImportEmployeesMutation({
    onError(data) {
      toast.error(ApiUtils.getErrorMessage(data));
    },
    onCompleted(data) {
      if (action === EmployeeImportFormat.Create) {
        const importSuccess = data.importEmployees.every((o) => o.imported);
        if (importSuccess) {
          toast.success('Employees imported successfully');
        }
      } else {
        // Show the successfully imported sheets
        const importSuccessSheets = data.importEmployees
          .filter((o) => o.imported)
          .map((o) => o.sheetName)
          .join(', ');

        if (importSuccessSheets.length) {
          toast.success(importSuccessSheets + ' imported successfully');
        }
      }

      if (data.importEmployees.every((o) => o.imported)) {
        onClose();
      }
    },
    refetchQueries: ['listEmployees'],
  });

  const [verifyGoogleOfflineToken] = useVerifyGoogleOfflineTokenMutation();
  const [generateGoogleOfflineOAuthUrl] =
    useGenerateGoogleOfflineOAuthUrlLazyQuery({
      onError(data) {
        toast.error(ApiUtils.getErrorMessage(data));
      },
    });

  const handleUpload = async (data: EmployeeListImportFormData) => {
    setLoading(true);

    const offlineToken = await verifyGoogleOfflineToken();
    if (!offlineToken.data?.verifyGoogleOfflineToken) {
      const authState = getStorageItem(AUTH_STATE_KEY);
      const response = await generateGoogleOfflineOAuthUrl({
        variables: {
          state: authState ?? '',
          redirectUri: process.env.REACT_APP_EMPLOYEE_CALLBACK_URL,
        },
      });

      const url = response.data?.generateGoogleOfflineOAuthUrl;
      RouterUtils.externalRedirect(url);
    }

    const response = await importEmployees({
      variables: {
        format: data.action,
        url: data.link,
      },
    });

    setErrorOutputs(response.data?.importEmployees.filter((i) => !i.imported));
    setLoading(false);
  };

  return (
    <form
      noValidate
      className='flex flex-col space-y-6'
      onSubmit={handleSubmit(handleUpload)}
    >
      <span>Import employees in bulk via Google spreadsheet.</span>

      <Controller
        control={control}
        name='action'
        render={({ field: { onChange, ...fields } }) => {
          return (
            <Radio.Group
              {...fields}
              className='flex flex-col space-y-4'
              error={errors.action?.message}
              onChange={(v) => {
                onChange(v);
                // Reset fields
                setErrorOutputs([]);
                setValue('link', '');
              }}
            >
              <Radio
                label='Create new employees'
                value={EmployeeImportFormat.Create}
              />
              <Radio
                label='Update Name'
                value={EmployeeImportFormat.GeneralInformation}
              />
              <Radio
                label='Update Manager/Job/Contract information'
                value={EmployeeImportFormat.JobHistoryContract}
              />
            </Radio.Group>
          );
        }}
      />

      <div className='flex flex-col'>
        <span>Please paste the link to your spreadsheet below.</span>
        <span className='text-link'>Need help importing?</span>
      </div>

      <EmployeeListImportErrors
        outputs={errorOutputs}
        showSheet={action === EmployeeImportFormat.JobHistoryContract}
      />

      <div className='relative'>
        <TextInput
          {...register('link')}
          placeholder={'https://docs.google.com/spreadsheets/d/...'}
          type='text'
          name='link'
          label='Google Drive Link'
          error={errors.link?.message}
          required
        />

        {action === EmployeeImportFormat.Create && (
          <EmployeeListImportTemplate />
        )}
      </div>

      <ModalFooter>
        <Button variant='default' onClick={onClose}>
          Cancel
        </Button>
        <Button className='bg-blue-6' type='submit' loading={loading}>
          Upload
        </Button>
      </ModalFooter>
    </form>
  );
};
