import { EmployeeExternalAccountStatus } from '@/shared/graphql';
import { z } from 'zod';

export const ExternalAccountsFilter = z.object({
  google: z.array(z.nativeEnum(EmployeeExternalAccountStatus)).catch([]),
  greenhouse: z.array(z.nativeEnum(EmployeeExternalAccountStatus)).catch([]),
  hubspot: z.array(z.nativeEnum(EmployeeExternalAccountStatus)).catch([]),
  slack: z.array(z.nativeEnum(EmployeeExternalAccountStatus)).catch([]),
  eloomi: z.array(z.nativeEnum(EmployeeExternalAccountStatus)).catch([]),
});
export type ExternalAccountsFilterType = z.infer<typeof ExternalAccountsFilter>;
