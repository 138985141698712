import { toast } from '@/shared/components';
import {
  ContractType,
  EmployeeCompanyAssetType,
  EmployeeGender,
  EmployeeHiringSource,
  JobHistoryLevel,
  useCreateEmployeeMutation,
} from '@/shared/graphql';
import { EmployeeAddFormDataType } from '@/shared/types';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from 'src/utils/api.utils';
import { ObjectUtils } from 'src/utils/object.utils';

export const useCreateEmployee = () => {
  const [createRecord, { loading }] = useCreateEmployeeMutation();
  const navigate = useNavigate();

  const handleCreateEmployee = (formData: EmployeeAddFormDataType) => {
    createRecord({
      variables: {
        input: {
          generalInformation: {
            companyEmail: {
              account: formData.emailUsername || '',
              domainId: formData.emailDomain || '',
            },
            dottedLineManagerId: formData.dottedLineManagerId ?? null,
            greenhouseLink: formData.greenhouseLink,
            hiredDate: formData.hiredDate,
            hiringSource: formData.hiringSource as EmployeeHiringSource,
            isNewGraduate: formData.isNewGraduate,
            legalFullName: formData.legalFullName || '',
            nameInLocalLanguage: formData.nameInLocalLanguage,
            japanesePhoneticName: formData.japanesePhoneticName,
            nationalityId: formData.nationalityId || '',
            needsProbationReview: formData.needsProbationReview,
            preferredFullName: formData.preferredFullName || '',
            primaryManagerId: formData.primaryManagerId || '',
          },
          personalInformation: {
            bank: {
              accountHolderName: formData.accountHolderName,
              accountNumber: formData.accountNumber,
              branchName: formData.branchName,
              countryId: formData.bankCountryCode,
              name: formData.bankName,
            },
            consultingFeeBank: {
              accountHolderName: formData.consultingFeeAccountHolderName,
              accountNumber: formData.consultingFeeAccountNumber,
              branchName: formData.consultingFeeBranchName,
              countryId: formData.consultingFeeBankCountryCode,
              currencyId: formData.consultingFeeBankCurrencyId,
              name: formData.consultingFeeBankName,
            },
            currentAddress: formData.currentAddress,
            dateOfBirth: formData.dateOfBirth,
            gender: formData.gender as EmployeeGender,
            idExpiryDate: formData.idExpiryDate,
            idIssuedDate: formData.idIssuedDate,
            idIssuedPlace: formData.idIssuedPlace,
            idNumber: formData.idNumber,
            idType: formData.idType,
            maritalStatus: formData.maritalStatus,
            nhiDependents: formData.nhiDependents,
            passportExpiryDate: formData.passportExpiryDate,
            passportIssuedDate: formData.passportIssuedDate,
            passportIssuedPlace: formData.passportIssuedPlace,
            passportName: formData.passportName,
            passportNumber: formData.passportName,
            passportPlaceOfBirth: formData.placeOfBirth,
            pensionVoluntaryContributionRate:
              formData.pensionVoluntaryContributionRate,
            permanentAddress: formData.permanentAddress,
            personalEmail: formData.personalEmail,
            personalPhoneNumber:
              formData.personalPhoneNumberCountryCode &&
              formData.personalPhoneNumber
                ? {
                    countryId: formData.personalPhoneNumberCountryCode,
                    number: formData.personalPhoneNumber,
                  }
                : null,
            socialInsuranceNotes: formData.socialInsuranceNotes,
            socialInsuranceNumber: formData.socialInsuranceNumber,
            taxCode: formData.taxCode,
            taxWithholdingRate: formData.taxWithholdingRate,
            residentialCountryId: formData.residentialCountryId,
            emergencyContacts: formData.emergencyContacts.map((item) => ({
              name: item.name,
              relation: item.relation,
              phoneNumber: {
                countryId: item.phoneNumberCountryCode,
                number: item.phoneNumber,
              },
              remarks: item.remarks,
            })),
            visas: formData.visas.map((v) =>
              ObjectUtils.deleteFields(v, ['id'])
            ),
            dependents: formData.dependents.map((v) =>
              ObjectUtils.deleteFields(v, ['id'])
            ),
            companyAssets: formData.companyAsset.map((item) => ({
              name: item.assetName,
              number: item.assetNumber,
              type: item.assetType || EmployeeCompanyAssetType.Others,
              assignedDate: item.assignedDate,
              returnedDate: item.returnedDate,
            })),
            remarks: formData.remarks,
          },
          currentContract: {
            companyId: formData.companyId || '',
            contractType: formData.contractType as ContractType,
            employeeCode: formData.employeeCode?.length
              ? formData.employeeCode
              : undefined,
            contractEndDate: formData.contractEndDate,
          },
          currentJobHistory: {
            level: formData.level as JobHistoryLevel,
            countryId: formData.countryId || 'AU',
            locationId: formData.locationId || '',
            businessUnitId: formData.businessUnitId || '',
            departmentId: formData.departmentId || '',
            jobId: formData.jobId || '',
            jobTitle: formData.jobTitle || '',
            team: formData.team,
            regionalRole: formData.regionalRole,
          },
          permission: {
            role: formData.role,
            employeeIds: formData.employeeIds,
            companyIds: formData.companyIds,
          },
          externalAccount: {
            autoCreationSettings: {
              enableGoogle: formData.autoCreateGoogleWorkspace,
              enableGreenhouse: formData.autoCreateGreenhouse,
            },
            timingToCreate: formData.timingToCreateAccounts,
          },
        },
      },
      onCompleted: () => {
        toast.success();
        navigate('/employees');
      },
      onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
    });
  };

  return {
    handleCreateEmployee,
    loading,
  };
};
