import {
  EMPTY_DEFAULT,
  JobHistoryLevelLabels,
  NoResult,
  Table,
  TableLayout,
  TableProps,
} from '@/shared/components';
import { JobHistoryLevel } from '@/shared/graphql';
import { EmployeeDirectReportType } from '@/shared/types';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const DirectReports = ({
  directReports,
}: {
  directReports: EmployeeDirectReportType[];
}) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          minWidth: '10rem',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return (
              <Link
                to={`/employees/edit/${directReport.employeeId}`}
                className='text-wrap text-blue-6 hover:cursor-pointer'
              >
                {directReport.name}
              </Link>
            );
          },
        },
        {
          headerRender: () => 'Company',
          accessor: 'company',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.company}</span>;
          },
        },

        {
          headerRender: () => 'Location',
          accessor: 'location',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.location || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Division',
          accessor: 'division',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.division || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Business Unit',
          accessor: 'businessUnit',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.businessUnit || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Job',
          accessor: 'job',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.job || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Level',
          accessor: 'level',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return (
              <span>
                {directReport.level
                  ? JobHistoryLevelLabels[directReport.level as JobHistoryLevel]
                  : EMPTY_DEFAULT}
              </span>
            );
          },
        },
        {
          headerRender: () => 'Job Title',
          accessor: 'jobTitle',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.jobTitle || '-'}</span>;
          },
        },
      ] as TableLayout<EmployeeDirectReportType>[],
    []
  );

  const tableCommonProps: TableProps<EmployeeDirectReportType> = {
    layout,
    loading: false,
    data: directReports || [],
    notFoundTemplate: (
      <NoResult
        title={'No direct reports'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <div className='mt-10'>
      <h1 className='mb-4 text-lg font-semibold'>Direct Reports</h1>
      <Table<EmployeeDirectReportType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </div>
  );
};
