import { toast } from '@/shared/components';
import {
  useCreateEmployeeVisaMutation,
  useDeleteEmployeeVisaMutation,
  useUpdateEmployeeVisaMutation,
} from '@/shared/graphql';
import { EmployeeVisaType } from '@/shared/types';
import { ApiUtils } from '@/shared/utils';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

interface VisaPersistence {
  employeeId: string;
  visas: EmployeeVisaType[];
  onVisasChange: (data: EmployeeVisaType[]) => void;
  // this triggers Mutation to be triggered, otherwise we let user
  // handle manually the data that was modified (e.g. to be saved later)
  autoPersistInDatabase?: boolean;
}

export const useVisaPersistence = ({
  employeeId,
  visas = [],
  onVisasChange,
  autoPersistInDatabase = true,
}: VisaPersistence) => {
  const [createRecord] = useCreateEmployeeVisaMutation();
  const [updateRecord] = useUpdateEmployeeVisaMutation();
  const [deleteRecord] = useDeleteEmployeeVisaMutation();

  const onVisaCreate = useCallback(
    ({ id, ...formData }: EmployeeVisaType) => {
      if (autoPersistInDatabase) {
        createRecord({
          variables: {
            input: {
              employeeId,
              ...formData,
            },
          },
          onCompleted: (data) => {
            toast.success();
            onVisasChange([
              ...visas,
              { ...formData, id: data.createEmployeeVisa.id },
            ]);
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onVisasChange([...visas, { ...formData, id: uuid() }]);
      }
    },
    [autoPersistInDatabase, visas, createRecord, employeeId, onVisasChange]
  );

  const onVisaUpdate = useCallback(
    (formData: EmployeeVisaType) => {
      if (autoPersistInDatabase) {
        updateRecord({
          variables: {
            input: {
              ...formData,
            },
          },
          onCompleted: () => {
            toast.success();
            onVisasChange([
              ...visas.map((item) =>
                item.id === formData.id ? { ...item, ...formData } : item
              ),
            ]);
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onVisasChange([
          ...visas.map((item) =>
            item.id === formData.id ? { ...item, ...formData } : item
          ),
        ]);
      }
    },
    [autoPersistInDatabase, visas, onVisasChange, updateRecord]
  );

  const onVisaRemove = useCallback(
    (id: string) => {
      if (autoPersistInDatabase) {
        deleteRecord({
          variables: { id: id },
          onCompleted: () => {
            toast.success();
            onVisasChange(visas.filter((item) => item.id !== id));
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onVisasChange(visas.filter((item) => item.id !== id));
      }
    },
    [autoPersistInDatabase, visas, deleteRecord, onVisasChange]
  );

  return {
    onVisaCreate,
    onVisaUpdate,
    onVisaRemove,
  };
};
