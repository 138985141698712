import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import {
  EmergencyContactType,
  EmployeePersonalInformationFormDataType,
} from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { useFormContext } from 'react-hook-form';
import { EmergencyContactModal } from './EmergencyContactModal';
import { EmergencyContactTable } from './EmergencyContactTable';

interface EmergencyContactsProps {
  onCreate: (data: EmergencyContactType) => void;
  onUpdate: (data: EmergencyContactType) => void;
  onRemove: (id: string) => void;
}

export const EmergencyContacts = ({
  onCreate,
  onUpdate,
  onRemove,
}: EmergencyContactsProps) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<EmployeePersonalInformationFormDataType>();

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const [emergencyContacts] = watch(['emergencyContacts']);
  const { hasPersonalInformationEditAccess } = useEmployeeAccess();
  const isEditable = hasPersonalInformationEditAccess || isNewRecord;

  return (
    <div className='mb-6'>
      <div className='mb-4 flex flex-row'>
        <h1 className='self-center'>Emergency Contacts</h1>{' '}
        {isEditable && (
          <EmergencyContactModal
            className='ml-auto'
            isNewRecord={true}
            onSuccess={onCreate}
          >
            <Button variant='default'>Add New Contacts</Button>
          </EmergencyContactModal>
        )}
      </div>
      <EmergencyContactTable
        items={emergencyContacts}
        loading={false}
        onRemove={onRemove}
        onUpdate={onUpdate}
        isEditable={isEditable}
      />
      {errors.emergencyContacts && (
        <span className='text-xs text-red-6'>
          {errors.emergencyContacts.message}
        </span>
      )}
    </div>
  );
};
