import {
  CountryLazySelect,
  CurrencyLazySelect,
  DEFAULT_PAGE_SIZE,
} from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeePersonalInformationFormDataType } from '@/shared/types';
import { TextInput } from '@any-ui-react/core';
import { useFormContext } from 'react-hook-form';

export const ConsultingFeeFormFields = () => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<EmployeePersonalInformationFormDataType>();
  const [consultingFeeBankCountryCode, consultingFeeBankCurrencyId] = watch([
    'consultingFeeBankCountryCode',
    'consultingFeeBankCurrencyId',
  ]);

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const { hasPersonalInformationEditAccess } = useEmployeeAccess();
  const isEditable = hasPersonalInformationEditAccess || isNewRecord;

  return (
    <div className='mb-6'>
      <fieldset className='form-fieldset'>
        <CurrencyLazySelect
          isMulti={false}
          error={errors.consultingFeeBankCurrencyId?.message}
          className='select-has-min-w-menu'
          disabled={!isEditable}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              keyword: null,
            },
          }}
          placeholder='Select receiver’s currency'
          label='Consulting Fee Type'
          onChange={(option) => {
            setValue('consultingFeeBankCurrencyId', option?.value as string);
          }}
          value={consultingFeeBankCurrencyId}
        />
        <CountryLazySelect
          isMulti={false}
          error={errors.consultingFeeBankCountryCode?.message}
          className='select-has-min-w-menu'
          disabled={!isEditable}
          defaultQueryOptions={{
            variables: {
              keyword: null,
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              hasCompanies: null,
            },
          }}
          placeholder={'Select Bank Country'}
          onChange={(option) => {
            setValue('consultingFeeBankCountryCode', option?.value as string);
          }}
          value={consultingFeeBankCountryCode}
          label='Bank Country'
        />
        <TextInput
          {...register('consultingFeeBankName')}
          disabled={!isEditable}
          placeholder='Enter Bank Name'
          label='Bank Name'
        />
        <TextInput
          {...register('consultingFeeBranchName')}
          disabled={!isEditable}
          placeholder='Enter Branch Name'
          label='Branch Name'
        />
        <TextInput
          {...register('consultingFeeAccountNumber')}
          disabled={!isEditable}
          placeholder='Enter Account Number'
          label='Account Number'
        />
        <TextInput
          {...register('consultingFeeAccountHolderName')}
          disabled={!isEditable}
          placeholder='Enter Account Holder Name'
          label='Account Holder Name'
        />
      </fieldset>
    </div>
  );
};
