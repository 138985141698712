import { ReadonlyDetail } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { DateUtils } from '@/shared/utils';

export const PassportDetails = () => {
  const { employeeDetail } = useEmployeeDetailContext();

  return (
    <div className='mb-6'>
      <fieldset className='form-fieldset'>
        <ReadonlyDetail
          label='Passport Number'
          value={employeeDetail.personalInformation.passportNumber}
        />

        <ReadonlyDetail
          label='Full Name on Passport'
          value={employeeDetail.personalInformation.passportName}
        />

        <ReadonlyDetail
          label='Passport Issued Place'
          value={employeeDetail.personalInformation.passportIssuedPlace}
        />

        <ReadonlyDetail
          label='Passport Issued Date'
          value={
            employeeDetail.personalInformation.passportIssuedDate
              ? DateUtils.formatDate(
                  new Date(
                    employeeDetail.personalInformation.passportIssuedDate || ''
                  ).toISOString(),
                  'yyyy-MM-dd'
                )
              : null
          }
        />

        <ReadonlyDetail
          label='Passport Expiry Date'
          value={
            employeeDetail.personalInformation.passportExpiryDate
              ? DateUtils.formatDate(
                  new Date(
                    employeeDetail.personalInformation.passportExpiryDate || ''
                  ).toISOString(),
                  'yyyy-MM-dd'
                )
              : null
          }
        />

        <ReadonlyDetail
          label='Place of Birth'
          value={employeeDetail.personalInformation.placeOfBirth}
        />
      </fieldset>
    </div>
  );
};
