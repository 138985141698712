import { CurrenciesEnum, CurrenciesEnumType } from '../enums';
import { NumberUtils } from './number.utils';

export class CurrencyUtils {
  static DEFAULT = CurrenciesEnum.JPY;

  static isCurrency = (currency = ''): currency is CurrenciesEnumType =>
    currency in CurrenciesEnum;

  static asCurrency = (currency?: string | null) =>
    currency && this.isCurrency(currency) ? currency : CurrencyUtils.DEFAULT;

  static convertByCurrencyPrecision = (
    amount: number,
    currency: CurrenciesEnumType | string = CurrencyUtils.DEFAULT
  ) => {
    try {
      const precision = CurrencyUtils.getRelevantDecimals(currency);
      const exponential = Number(`1e${precision}`);
      return Math.round(amount * exponential) / exponential;
    } catch (e) {
      return amount;
    }
  };

  static getRelevantDecimals(
    currency: CurrenciesEnumType | string = CurrencyUtils.DEFAULT
  ) {
    const format = NumberUtils.format(1000, { currency, style: 'currency' });
    return format?.split('.')?.[1]?.length || 0;
  }
}
