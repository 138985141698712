import {
  NoResult,
  Table,
  TableLayout,
  TableProps,
  TrashIcon,
  useDeleteRecordModal,
} from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeeDependentType } from '@/shared/types';
import { StringUtils } from '@/shared/utils';
import { ActionIcon } from '@any-ui-react/core';
import { useMemo } from 'react';
import { DependentModal } from './DependentModal';

interface DependentTableProps {
  loading: boolean;
  items: EmployeeDependentType[];
  onRemove: (id: string) => void;
  onUpdate: (data: EmployeeDependentType) => void;
  showSelected?: boolean;
  isEditable?: boolean;
}

export const DependentTable = ({
  items,
  loading,
  onUpdate,
  onRemove,
  isEditable,
}: DependentTableProps) => {
  const { openDeleteRecordModal } = useDeleteRecordModal();

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const { hasPersonalInformationDeleteAccess } = useEmployeeAccess();
  const isRemovable = hasPersonalInformationDeleteAccess || isNewRecord;

  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (dependent: EmployeeDependentType) => {
            return isEditable ? (
              <DependentModal dependent={dependent} onSuccess={onUpdate}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {dependent.name}
                </span>
              </DependentModal>
            ) : (
              dependent.name
            );
          },
        },
        {
          headerRender: () => 'Relation',
          accessor: 'relation',
          cellRender: (dependent: EmployeeDependentType) => {
            return (
              <span>
                {StringUtils.capitalizeAll(dependent.relation || '', '_')}
              </span>
            );
          },
        },
        {
          headerRender: () => 'Date of Birth',
          accessor: 'dateOfBirth',
          cellRender: (dependent: EmployeeDependentType) => {
            return <span>{dependent.dateOfBirth}</span>;
          },
        },
        {
          headerRender: () => 'Age',
          accessor: 'age',
          cellRender: (dependent: EmployeeDependentType) => {
            return <span>{'-'}</span>;
          },
        },
        ...(isRemovable
          ? [
              {
                headerRender: () => '',
                accessor: 'delete',
                cellRender: (dependent: EmployeeDependentType) => {
                  return (
                    <ActionIcon
                      size='xs'
                      onClick={() => {
                        openDeleteRecordModal({
                          onSuccess: () => onRemove(dependent.id),
                        });
                      }}
                      className='ml-auto'
                    >
                      <TrashIcon />
                    </ActionIcon>
                  );
                },
              },
            ]
          : []),
      ] as TableLayout<EmployeeDependentType>[],
    [isEditable, isRemovable, onRemove, onUpdate, openDeleteRecordModal]
  );

  const tableCommonProps: TableProps<EmployeeDependentType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No dependents found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<EmployeeDependentType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
