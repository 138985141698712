import {
  CountryLazySelect,
  DatePicker,
  DEFAULT_PAGE_SIZE,
  GenderSelect,
  PhoneNumberInput,
} from '@/shared/components';
import { EmployeeGender } from '@/shared/graphql';
import {
  EmployeePersonalInformationFormDataType,
  FormAction,
} from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { Textarea, TextInput } from '@any-ui-react/core';
import { CountryCode } from 'libphonenumber-js';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  mode?: FormAction;
}

export const PersonalInformationFormFields = ({
  mode = FormAction.CREATING,
}: Props) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
    clearErrors,
    control,
  } = useFormContext<EmployeePersonalInformationFormDataType>();

  const [
    gender,
    dateOfBirth,
    personalPhoneNumber,
    personalPhoneNumberCountryCode,
  ] = watch([
    'gender',
    'dateOfBirth',
    'personalPhoneNumber',
    'personalPhoneNumberCountryCode',
  ]);

  return (
    <fieldset className='form-fieldset'>
      <GenderSelect
        required
        label={'Gender'}
        placeholder='Select Gender'
        value={gender}
        error={errors.gender?.message}
        onChange={(value) => {
          setValue('gender', value as EmployeeGender, {
            shouldDirty: true,
          });
          clearErrors('gender');
        }}
      />
      <DatePicker
        label='Date of Birth'
        required={mode === FormAction.CREATING}
        error={errors.dateOfBirth?.message}
        placeholder='Select Date of Birth'
        value={dateOfBirth ?? undefined}
        onChange={(value) => {
          setValue(
            'dateOfBirth',
            DateUtils.formatDate(
              new Date(value || '').toISOString(),
              'yyyy-MM-dd'
            ),
            {
              shouldDirty: true,
            }
          );
          clearErrors('dateOfBirth');
        }}
      />
      <PhoneNumberInput
        {...register('personalPhoneNumber')}
        defaultValue={{
          countryCode: (personalPhoneNumberCountryCode as CountryCode) ?? 'JP',
          number: personalPhoneNumber,
        }}
        onChange={({ number, countryCode }) => {
          setValue('personalPhoneNumber', number, {
            shouldDirty: true,
          });
          if (countryCode) {
            setValue('personalPhoneNumberCountryCode', countryCode, {
              shouldDirty: true,
            });
          }
          clearErrors('personalPhoneNumber');
          clearErrors('personalPhoneNumberCountryCode');
        }}
        label='Personal Phone number'
        error={
          errors.personalPhoneNumber?.message ||
          errors.personalPhoneNumberCountryCode?.message
        }
        placeholder='9012345678'
      />

      <TextInput
        {...register('personalEmail')}
        label='Personal Email'
        placeholder='user@gmail.com'
        error={errors.personalEmail?.message}
      />

      <Controller
        control={control}
        name='residentialCountryId'
        render={({ field: { ...field } }) => (
          <CountryLazySelect
            isMulti={false}
            label={'Residential Location'}
            required
            isSearchable
            error={errors.residentialCountryId?.message}
            valueFormatter={(item) => item.id}
            className='select-has-min-w-menu'
            defaultQueryOptions={{
              variables: {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                keyword: null,
                hasCompanies: null,
              },
            }}
            placeholder={'Select Residential Location'}
            {...field}
            onChange={(option) => {
              setValue('residentialCountryId', option?.value as string, {
                shouldDirty: true,
              });
            }}
          />
        )}
      />

      <div className='col-span-2 col-start-1'>
        <Textarea
          {...register('currentAddress')}
          minRows={4}
          label='Current Address'
          className='w-8/12'
          placeholder='106-6131 Roppongi Hills 31F, Roppongi, Minato-ku, Tokyo'
        />
      </div>
      <div className='col-span-2 col-start-1'>
        <Textarea
          {...register('permanentAddress')}
          minRows={4}
          label='Permanent Address'
          className='w-8/12'
          placeholder='106-6131 Roppongi Hills 31F, Roppongi, Minato-ku, Tokyo'
        />
      </div>
    </fieldset>
  );
};
