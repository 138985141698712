import {
  BankFormFields,
  CardTitle,
  CompanyAsset,
  ConsultingFeeFormFields,
  Dependent,
  EmergencyContacts,
  IdentificationFormFields,
  PassportFormFields,
  PersonalInformationFormFields,
  SocialInsuranceFormFields,
  Visa,
} from '@/shared/components';
import {
  useCompanyAssetPersistence,
  useDependentPersistence,
  useEmergencyContactPersistence,
  useVisaPersistence,
} from '@/shared/hooks';
import { EmployeeAddFormDataType } from '@/shared/types';
import { CardSection, Textarea } from '@any-ui-react/core';
import { useFormContext } from 'react-hook-form';

export const PersonalInformation = () => {
  const { setValue, getValues, register } =
    useFormContext<EmployeeAddFormDataType>();

  const { onVisaCreate, onVisaUpdate, onVisaRemove } = useVisaPersistence({
    employeeId: '',
    visas: getValues('visas'),
    onVisasChange: (data) => setValue('visas', data),
    autoPersistInDatabase: false,
  });

  const { onCompanyAssetCreate, onCompanyAssetUpdate, onCompanyAssetRemove } =
    useCompanyAssetPersistence({
      employeeId: '',
      companyAssets: getValues('companyAsset'),
      onCompanyAssetsChange: (data) => setValue('companyAsset', data),
      autoPersistInDatabase: false,
    });

  const { onDependentCreate, onDependentUpdate, onDependentRemove } =
    useDependentPersistence({
      employeeId: '',
      dependents: getValues('dependents'),
      onDependentsChange: (data) => setValue('dependents', data),
      autoPersistInDatabase: false,
    });

  const {
    onEmergencyContactCreate,
    onEmergencyContactUpdate,
    onEmergencyContactRemove,
  } = useEmergencyContactPersistence({
    employeeId: '',
    emergencyContacts: getValues('emergencyContacts'),
    onEmergencyContactsChange: (data) => setValue('emergencyContacts', data),
    autoPersistInDatabase: false,
  });

  return (
    <CardSection>
      <CardTitle>Personal Information</CardTitle>
      <PersonalInformationFormFields />
      <EmergencyContacts
        onCreate={onEmergencyContactCreate}
        onUpdate={onEmergencyContactUpdate}
        onRemove={onEmergencyContactRemove}
      />
      <IdentificationFormFields />
      <PassportFormFields />
      <Visa
        onCreate={onVisaCreate}
        onUpdate={onVisaUpdate}
        onRemove={onVisaRemove}
      />
      <SocialInsuranceFormFields />
      <Dependent
        onCreate={onDependentCreate}
        onUpdate={onDependentUpdate}
        onRemove={onDependentRemove}
      />
      <BankFormFields />
      <ConsultingFeeFormFields />
      <Textarea
        {...register('remarks')}
        label={'Remarks'}
        minRows={5}
        className='col-span-2 mb-6'
        placeholder='Enter Remarks'
      />

      <CompanyAsset
        onCreate={onCompanyAssetCreate}
        onUpdate={onCompanyAssetUpdate}
        onRemove={onCompanyAssetRemove}
      />
    </CardSection>
  );
};
