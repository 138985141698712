'use client';

import { Menu, MenuButton, MenuDivider, MenuItem } from '@szhsin/react-menu';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { StringUtils, removeAuthUserInSession } from '../../../../../utils';
import { SignOutIcon } from '../../../icons';

export const UserMenu = () => {
  const { authUser, setAuthUser } = useAuthContext();
  const { name, email, role, pictureUrl } = authUser;
  const navigate = useNavigate();

  return (
    <Menu
      menuButton={({ open }) => (
        <MenuButton
          className={classNames(
            'group flex w-full cursor-pointer items-center px-4 py-3 text-xs font-semibold hover:bg-gray-0 lg:p-4',
            {
              'bg-gray-0': open,
            }
          )}
        >
          <img
            alt={name}
            className='h-6 w-6 rounded-full'
            src={pictureUrl}
            width={50}
            height={50}
            referrerPolicy='no-referrer'
          />
          <span className='ml-2 hidden first-letter:uppercase lg:block'>
            {name}
          </span>
        </MenuButton>
      )}
      direction='top'
      viewScroll='auto'
      align='start'
      shift={0}
      portal={true}
      menuClassName='border-l-0 border-r-0 border-b-0 shadow-user bg-gr rounded-none w-60'
    >
      <div className='p-4'>
        <p className='text-3xs font-normal text-gray-7'>
          {StringUtils.capitalizeAll(role, '_')}
        </p>
        <p className='mt-2 text-2xs font-semibold'>{name}</p>
        <p className='text-main mt-1 block overflow-hidden overflow-ellipsis whitespace-nowrap text-3xs font-normal'>
          {email}
        </p>
      </div>
      <MenuDivider className='m-0' />

      <MenuItem
        onClick={() => {
          setAuthUser(removeAuthUserInSession());
          localStorage.clear();
          navigate('/login');
        }}
        className={
          'text-main m-2 flex items-center rounded-sm px-2 py-1 font-semibold hover:bg-gray-0'
        }
      >
        <SignOutIcon className='h-5.5 w-5.5 fill-gray-7' />
        <span className='ml-2 text-xs'>Sign Out</span>
      </MenuItem>
      <MenuDivider className='m-0' />
    </Menu>
  );
};
