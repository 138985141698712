import { EmployeeExportFormat } from '@/shared/graphql';
import classNames from 'classnames';
import { Spinner, toast } from 'src/components/shared';
import { ApiUtils } from 'src/utils/api.utils';
import { RouterUtils } from 'src/utils/router.utils';
import { useExportEmployeesLazyQuery } from '../../graphql';

export const EmployeeListImportTemplate = () => {
  const [exportEmployees, { loading }] = useExportEmployeesLazyQuery({
    onError(data) {
      toast.error(ApiUtils.getErrorMessage(data));
    },
    onCompleted(data) {
      if (data.exportEmployees) {
        RouterUtils.externalRedirect(data.exportEmployees, true);
      }
    },
  });

  const generateTemplate = async () => {
    await exportEmployees({
      variables: {
        format: EmployeeExportFormat.CreateTemplate,
        filter: null,
        employeeIds: null,
      },
    });
  };

  return (
    <div
      className={classNames('absolute right-0 top-0 text-4xs', {
        'pointer-events-none flex items-center text-gray-7': loading,
        'cursor-pointer text-link hover:underline': !loading,
      })}
      onClick={generateTemplate}
    >
      {loading && <Spinner className='mr-1 size-2.5' />}
      Spreadsheet Template
    </div>
  );
};
