import { ReadonlyDetail } from '@/shared/components';
import { EmployeeDetailType } from '@/shared/types';
import { StringUtils } from '@/shared/utils';

export const PersonalInformationDetails = ({
  employee,
}: {
  employee: EmployeeDetailType;
}) => {
  const employeePersonalInformation = employee.personalInformation;
  return (
    <div>
      <div className='form-fieldset'>
        <ReadonlyDetail
          label='Gender'
          value={
            employeePersonalInformation.gender
              ? StringUtils.capitalizeAll(
                  employeePersonalInformation.gender,
                  '_'
                )
              : null
          }
        />

        <ReadonlyDetail
          label='Nationality'
          value={employee.generalInformation.nationality}
        />

        <ReadonlyDetail
          label='Date of Birth'
          value={employeePersonalInformation?.dateOfBirth}
        />

        <ReadonlyDetail
          label='Personal Phone Number'
          value={
            employeePersonalInformation.personalPhoneNumber ? (
              <>
                {employeePersonalInformation.personalPhoneNumberCountryCode}{' '}
                {employeePersonalInformation.personalPhoneNumber}
              </>
            ) : (
              '-'
            )
          }
        />

        <ReadonlyDetail
          label='Personal Email'
          value={employeePersonalInformation.personalEmail}
        />

        <ReadonlyDetail
          label='Current Address'
          value={employeePersonalInformation.currentAddress}
        />

        <ReadonlyDetail
          label='Permanent Address'
          value={employeePersonalInformation.permanentAddress}
        />
      </div>
    </div>
  );
};
