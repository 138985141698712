import { useEmployeeDetailContext } from '@/shared/contexts';
import {
  useContractHistoryPersistence,
  useEmployeeAccess,
} from '@/shared/hooks';
import { Button, Textarea } from '@any-ui-react/core';
import { useEffect, useState } from 'react';
import { ContractHistoryModal } from './contract-history-modal';
import { ContractHistoryTable } from './contract-history-table/ContractHistoryTable';

export const ContractHistory = ({
  onFormStateChange,
}: {
  onFormStateChange: (isDirty: boolean) => void;
}) => {
  const { employeeDetail } = useEmployeeDetailContext();
  const { hasJobContractHistoryEditAccess } = useEmployeeAccess();
  const [note, setNote] = useState(employeeDetail.contractNotes || '');

  const {
    onContractHistoryCreate,
    onContractHistoryUpdate,
    onContractHistoryRemove,
    onContractHistoryNoteUpdate,
  } = useContractHistoryPersistence({ employeeId: employeeDetail.id });

  useEffect(() => {
    const isDirty = note !== (employeeDetail.contractNotes || '');
    onFormStateChange(isDirty);
  }, [note, employeeDetail.contractNotes, onFormStateChange]);

  return (
    <>
      <div className='mb-4 flex flex-row'>
        <h1 className='self-center text-lg font-semibold'>Contract History</h1>{' '}
        {hasJobContractHistoryEditAccess && (
          <ContractHistoryModal
            className='ml-auto'
            isNewRecord={true}
            onSuccess={onContractHistoryCreate}
          >
            <Button variant='default'>Add Contract History</Button>
          </ContractHistoryModal>
        )}
      </div>
      <ContractHistoryTable
        items={employeeDetail.contractHistories ?? []}
        loading={false}
        onRemove={onContractHistoryRemove}
        onUpdate={onContractHistoryUpdate}
      />
      <div className='mb-10 mt-6'>
        <Textarea
          value={note}
          label='Note'
          placeholder='Enter Note'
          minRows={5}
          onChange={(e) => setNote(e.target.value)}
          disabled={!hasJobContractHistoryEditAccess}
        />
      </div>
      {hasJobContractHistoryEditAccess && (
        <>
          <hr />
          <div className='flex'>
            <Button
              onClick={() =>
                onContractHistoryNoteUpdate(note, employeeDetail.id)
              }
              className='my-4 ml-auto bg-blue-6'
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
};
