import {
  EmployeeRole,
  useCreateEmployeeListCustomFilterMutation,
  useUpdateEmployeeListCustomFilterMutation,
} from '@/shared/graphql';
import { FilterProps } from '@/shared/hooks';
import { Button, Checkbox, ModalFooter, TextInput } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/contexts/AuthContext';
import { EmployeeListFilterType } from '../EmployeeList.filters';
import {
  SaveCustomFilterFormData,
  saveCustomFilterFormValidationSuite,
} from './save-custom-filter-form.validator';

export interface SaveCustomFilterFormProps {
  filters: FilterProps<EmployeeListFilterType>;
  onSuccess: () => void;
  onFailure: () => void;
  onCancel: () => void;
}

export const SaveCustomFilterForm = ({
  filters: filterProps,
  onSuccess,
  onFailure,
  onCancel,
}: SaveCustomFilterFormProps) => {
  const filters = filterProps.current;
  const { authUser } = useAuthContext();
  const isSuperAdmin = authUser.role === EmployeeRole.SuperAdmin;
  const canUpdate = isSuperAdmin ? true : !filters.isOpenFilter;

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
  } = useForm<SaveCustomFilterFormData>({
    defaultValues: {
      name: filters.name ?? '',
      // Only super admins can create open filters
      myFilter: !isSuperAdmin ? true : !filters.isOpenFilter,
    },
    resolver: vestResolver(saveCustomFilterFormValidationSuite),
  });

  const myFilter = watch('myFilter');
  const [isCreate, setIsCreate] = useState(false);
  const [createRecord] = useCreateEmployeeListCustomFilterMutation();
  const [updateRecord] = useUpdateEmployeeListCustomFilterMutation();

  return (
    <form
      noValidate
      onSubmit={handleSubmit((formData) => {
        const variableInput = {
          businessUnitIds: filters.businessUnitIds || [],
          companyIds: filters.companyIds || [],
          contractTypes: filters.contractTypes || [],
          departmentIds: filters.departmentIds || [],
          managerIds: filters.managerIds || [],
          externalAccountGoogle: filters.externalAccount?.google || [],
          externalAccountGreenhouse: filters.externalAccount?.greenhouse || [],
          externalAccountHubspot: filters.externalAccount?.hubspot || [],
          externalAccountSlack: filters.externalAccount?.slack || [],
          externalAccountEloomi: filters.externalAccount?.eloomi || [],
          hiredDate: filters.hiredDate
            ? {
                start: filters.hiredDate.start,
                end: filters.hiredDate.end,
              }
            : null,
          employmentEndDate: filters.employmentEndDate
            ? {
                start: filters.employmentEndDate.start,
                end: filters.employmentEndDate.end,
              }
            : null,
          isLeave: filters.isLeave || false,
          isOnSecondment: filters.isOnSecondment || false,
          jobIds: filters.jobIds || [],
          keyword: filters.keyword,
          levels: filters.levels || [],
          locationIds: filters.locationIds || [],
          divisionIds: filters.divisionIds || [],
          nationalityIds: filters.nationalityIds || [],
          roles: filters.roles || [],
          statuses: filters.statuses || [],
          managerIncludesAllConnections:
            filters.managerIncludesAllConnections || false,
        };
        if (isCreate) {
          createRecord({
            variables: {
              input: {
                ...variableInput,
                name: formData.name,
                isOpenFilter: !formData.myFilter,
              },
            },
            onCompleted: (data) => {
              onSuccess();
              filterProps.updateFilters({
                ...filterProps.current,
                name: formData.name,
                id: data.createEmployeeListCustomFilter.id,
              });
            },
            onError: () => onFailure(),
          });
        } else {
          updateRecord({
            variables: {
              input: {
                ...variableInput,
                name: formData.name,
                isOpenFilter: !formData.myFilter,
                id: filters?.id ?? '',
              },
            },
            onCompleted: (data) => {
              onSuccess();
              filterProps.updateFilters({
                ...filterProps.current,
                name: formData.name,
                id: data.updateEmployeeListCustomFilter.id,
              });
            },
            onError: () => onFailure(),
          });
        }
      })}
    >
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          label={'Filter name'}
          required
          error={errors.name?.message}
          placeholder={'Enter name'}
          className='col-span-2'
        />
        <Checkbox
          {...register('myFilter')}
          error={errors.myFilter?.message}
          checked={myFilter}
          label={'My Filter'}
          onChange={(e) => {
            if (isSuperAdmin) {
              setValue('myFilter', e.currentTarget.checked, {
                shouldDirty: true,
              });
            }
          }}
        />
      </fieldset>
      <ModalFooter>
        {canUpdate && (
          <Button
            className='bg-blue-6'
            type='submit'
            onClick={() => setIsCreate(false)}
            loading={isSubmitting}
            disabled={filterProps.current.id === ''}
          >
            Update
          </Button>
        )}
        <Button
          className='bg-blue-6'
          type='submit'
          onClick={() => setIsCreate(true)}
          loading={isSubmitting}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
