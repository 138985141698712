import { Button, Radio } from '@any-ui-react/core';

import { CompanyLazySelect, DEFAULT_PAGE_SIZE } from '@/shared/components';
import {
  EmployeePermissionFormDataType,
  UserEmployeeType,
} from '@/shared/types';
import { Controller, useFormContext } from 'react-hook-form';
import { AddEmployeeModal } from './add-employee-modal';
import { EmployeeTable } from './EmployeeTable';

export const CompanyEmployeeAssignment = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<EmployeePermissionFormDataType>();
  const [companyIds, isAllEmployees, employees] = watch([
    'companyIds',
    'isAllEmployees',
    'employees',
  ]);
  const assignmentRadioOptions = [
    { label: 'All employees', value: '1' },
    { label: 'Specific employees', value: '0' },
  ];

  return (
    <>
      <div className='mb-3 mt-6'>
        <h1 className='mb-2 text-sm font-semibold'>
          Company and Employee Access
        </h1>
        <span>
          Which companies/employees information does this person need access to?
        </span>
      </div>
      <Controller
        control={control}
        name='companyIds'
        render={({ field: { value, ...field } }) => {
          return (
            <>
              <CompanyLazySelect
                {...field}
                isMulti
                required={true}
                label={'Companies'}
                className='w-6/12'
                value={value ?? []}
                valueFormatter={(item) => item.id}
                defaultQueryOptions={{
                  variables: {
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    keyword: null,
                    filterOnlyAccessible: false,
                    enabled: null,
                  },
                }}
                placeholder={'Select Companies'}
                error={errors.companyIds?.message}
                onChange={(option) => {
                  const changedOption = option.map((item) => item.value);
                  setValue('companyIds', changedOption, { shouldDirty: true });
                }}
              />
            </>
          );
        }}
      />
      <Controller
        control={control}
        name='isAllEmployees'
        render={({ field: { value, ...fields } }) => {
          return (
            <Radio.Group
              {...fields}
              value={value ? '1' : '0'}
              onChange={(value) => {
                setValue('isAllEmployees', value === '1', {
                  shouldDirty: true,
                });
              }}
              className='flex gap-x-4 py-6'
            >
              {assignmentRadioOptions.map(({ label, value }) => (
                <Radio key={label} label={label} value={value} />
              ))}
            </Radio.Group>
          );
        }}
      />
      {!isAllEmployees && (
        <>
          <div className='flex'>
            <div className='ml-auto'>
              <AddEmployeeModal
                className='w-fit'
                activeEmployees={employees}
                companyIds={companyIds}
                onSuccess={(selection: UserEmployeeType[]) => {
                  setValue('employees', selection, {
                    shouldDirty: true,
                  });
                  setValue(
                    'employeeIds',
                    selection.map((item) => item.id)
                  );
                }}
              >
                <Button variant='default' className='my-6' onClick={() => {}}>
                  Add Employee Access
                </Button>
              </AddEmployeeModal>
            </div>
          </div>
          <EmployeeTable items={employees ?? []} loading={false} />
        </>
      )}
    </>
  );
};
