import {
  BusinessUnitLazySelect,
  CompanyLazySelect,
  CountryLazySelect,
  DEFAULT_PAGE_SIZE,
  DepartmentLazySelect,
  JobLazySelect,
  LocationLazySelect,
  MonthPicker,
  ReportContractText,
  ReportContractTypeSelect,
} from '@/shared/components';
import { FilterProps } from '@/shared/hooks';
import { DemographicReportFilterType } from '@/shared/types';

export const DemographicReportFilters = ({
  filters,
}: {
  filters: FilterProps<DemographicReportFilterType>;
}) => {
  const { changeFilter, current } = filters;

  return (
    <div className='grid grid-cols-8 gap-2 border-b pb-4'>
      <div className='col-span-8 grid grid-cols-4 gap-2'>
        <div className='col-span-1'>
          <MonthPicker
            value={current.yearMonth}
            onChange={(v) => changeFilter('yearMonth', v)}
            minDate={new Date(2016, 3, 1)}
            maxDate={new Date()}
          />
        </div>
      </div>

      <div className='col-span-2'>
        <ReportContractTypeSelect
          isMulti={true}
          value={current.contractTypes.map((item) => ({
            label: String(ReportContractText[item]),
            value: item,
          }))}
          onChange={(newValue) => {
            changeFilter(
              'contractTypes',
              newValue.map(({ value }) => value)
            );
          }}
        />
      </div>

      <div className='col-span-2'>
        <CountryLazySelect
          isMulti
          className='select-has-min-w-menu'
          defaultQueryOptions={{
            variables: {
              keyword: null,
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              hasCompanies: null,
            },
          }}
          placeholder='Select Country'
          onChange={(option) => {
            changeFilter(
              'countryIds',
              option.map((v) => v.value)
            );
          }}
          value={current.countryIds}
        />
      </div>

      <div className='col-span-2'>
        <LocationLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.locationIds}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              enabled: null,
              keyword: null,
            },
          }}
          placeholder='Select Office Location'
          onChange={(option) => {
            changeFilter(
              'locationIds',
              option.map(({ value }) => value)
            );
          }}
        />
      </div>

      <div className='col-span-2'>
        <CompanyLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.companyIds}
          valueFormatter={(item) => item.id}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              keyword: null,
              filterOnlyAccessible: false,
              enabled: null,
            },
          }}
          placeholder='Select Company'
          onChange={(newValue) => {
            changeFilter(
              'companyIds',
              newValue.map(({ value }) => value)
            );
          }}
        />
      </div>

      <div className='col-span-2'>
        <BusinessUnitLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.businessUnitIds}
          onChange={(newValue) => {
            changeFilter(
              'businessUnitIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              keyword: null,
              enabled: null,
            },
          }}
          placeholder='Select Business Unit'
        />
      </div>

      <div className='col-span-2'>
        <DepartmentLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.departmentIds}
          valueFormatter={(item) => item.id}
          onChange={(newValue) => {
            changeFilter(
              'departmentIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              enabled: null,
            },
          }}
          placeholder='Select Department'
        />
      </div>

      <div className='col-span-2'>
        <JobLazySelect
          isMulti={true}
          className='select-has-min-w-menu'
          value={current.jobIds}
          valueFormatter={(item) => item.id}
          onChange={(newValue) => {
            changeFilter(
              'jobIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              enabled: null,
            },
          }}
          placeholder='Select Job'
        />
      </div>

      <div className='col-span-2'>
        <CountryLazySelect
          isMulti={true}
          className='select-has-min-w-menu'
          value={current.nationalityIds}
          onChange={(newValue) => {
            changeFilter(
              'nationalityIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              keyword: null,
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              hasCompanies: null,
            },
          }}
          placeholder='Select Nationality'
        />
      </div>
    </div>
  );
};
