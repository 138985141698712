import { toast } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useDraftProbationReviewForReviewerMutation } from '@/shared/graphql';
import { ProbationReviewDetailType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { vestResolver } from '@hookform/resolvers/vest';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ProbationDetails } from '../probation-details';
import { reviewerFormValidationSuite } from './reviewer-form.validator';

export const ReviewerForm = () => {
  const { employeeDetail } = useEmployeeDetailContext();

  const form = useForm<ProbationReviewDetailType>({
    defaultValues: employeeDetail.probationReview ?? undefined,
    resolver: vestResolver(reviewerFormValidationSuite),
  });

  useEffect(() => {
    form.reset(employeeDetail.probationReview ?? undefined);
  }, [employeeDetail.probationReview, form]);

  const [draftProbationReviewForReviewer, { loading: draftLoading }] =
    useDraftProbationReviewForReviewerMutation({
      onCompleted() {
        toast.success();
      },
      onError(data) {
        toast.error(data.message);
      },
    });

  const handleSaveDraft = async () => {
    const data = form.getValues();
    await draftProbationReviewForReviewer({
      variables: {
        input: {
          employeeId: employeeDetail.id,
          expectation: data.expectation,
          stages: data.stages.map((stage) => ({
            reviewerId: stage.reviewerId.length ? stage.reviewerId : null,
            reviewerFeedback: stage.reviewerFeedback?.length
              ? stage.reviewerFeedback
              : null,
            deadline: stage.deadline
              ? DateUtils.formatDate(new Date(stage.deadline), 'yyyy-MM-dd')
              : null,
          })),
        },
      },
    });
  };

  return (
    <FormProvider {...form}>
      <ProbationDetails
        onSaveDraft={handleSaveDraft}
        draftLoading={draftLoading}
      />
    </FormProvider>
  );
};
