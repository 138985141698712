import { JobHistoryType } from '@/shared/graphql';
import { Select, SelectProps } from '@any-ui-react/core';
import { forwardRef } from 'react';

interface JobHistoryTypeSelectProps extends Omit<SelectProps, 'data'> {}

export const JobHistoryTypeLabels: Record<JobHistoryType, string> = {
  [JobHistoryType.ChangeWithoutPromotionDemotion]:
    'Change Without Promotion / Demotion',
  [JobHistoryType.ExtendedLeave]: 'Extended Leave',
  [JobHistoryType.Join]: 'Join',
  [JobHistoryType.NewContract]: 'New Contract',
  [JobHistoryType.ParentalLeave]: 'Parental Leave',
  [JobHistoryType.PromotionDemotion]: 'Promotion / Demotion',
  [JobHistoryType.Transfer]: 'Transfer',
};

export const JobHistoryTypeSelect = forwardRef<
  HTMLInputElement,
  JobHistoryTypeSelectProps
>((props, ref) => {
  const types = Object.values(JobHistoryType).map((value) => ({
    label: JobHistoryTypeLabels[value],
    value: value,
  }));

  return (
    <Select
      data={types}
      withinPortal={true}
      dropdownPosition='bottom'
      ref={ref}
      {...props}
    />
  );
});
