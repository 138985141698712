import {
  NoResult,
  Table,
  TableLayout,
  TableProps,
  TrashIcon,
  useDeleteRecordModal,
} from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmergencyContactType } from '@/shared/types';
import { StringUtils } from '@/shared/utils';
import { ActionIcon } from '@any-ui-react/core';
import { useMemo } from 'react';
import { EmergencyContactModal } from './EmergencyContactModal';

interface EmergencyContactTableProps {
  loading: boolean;
  items: EmergencyContactType[];
  onRemove: (id: string) => void;
  onUpdate: (data: EmergencyContactType) => void;
  isEditable?: boolean;
}

export const EmergencyContactTable = ({
  items,
  loading,
  onRemove,
  onUpdate,
  isEditable,
}: EmergencyContactTableProps) => {
  const { openDeleteRecordModal } = useDeleteRecordModal();

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const { hasPersonalInformationDeleteAccess } = useEmployeeAccess();
  const isRemovable = hasPersonalInformationDeleteAccess || isNewRecord;

  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (emergencyContact: EmergencyContactType) => {
            return isEditable ? (
              <EmergencyContactModal
                className='ml-auto'
                emergencyContact={emergencyContact}
                onSuccess={onUpdate}
              >
                <span className='text-blue-6 hover:cursor-pointer'>
                  {emergencyContact.name}
                </span>
              </EmergencyContactModal>
            ) : (
              emergencyContact.name
            );
          },
        },

        {
          headerRender: () => 'Relation',
          accessor: 'relation',
          cellRender: (emergencyContact: EmergencyContactType) => {
            return (
              <span>
                {StringUtils.capitalizeAll(
                  emergencyContact.relation || '',
                  '_'
                )}
              </span>
            );
          },
        },
        {
          headerRender: () => 'Phone Number',
          accessor: 'phoneNumber',
          cellRender: (emergencyContact: EmergencyContactType) => {
            return <span>{emergencyContact.phoneNumber}</span>;
          },
        },
        {
          headerRender: () => 'Remarks',
          accessor: 'remarks',
          cellRender: (emergencyContact: EmergencyContactType) => {
            return <span>{emergencyContact.remarks}</span>;
          },
        },
        ...(isRemovable
          ? [
              {
                headerRender: () => '',
                accessor: 'delete',
                cellRender: (emergencyContact: EmergencyContactType) => {
                  return (
                    <ActionIcon
                      size='xs'
                      onClick={() => {
                        openDeleteRecordModal({
                          onSuccess: () => onRemove(emergencyContact.id),
                        });
                      }}
                      className='ml-auto'
                    >
                      <TrashIcon />
                    </ActionIcon>
                  );
                },
              },
            ]
          : []),
      ] as TableLayout<EmergencyContactType>[],
    [isEditable, isRemovable, onRemove, onUpdate, openDeleteRecordModal]
  );

  const tableCommonProps: TableProps<EmergencyContactType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No emergency contacts found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<EmergencyContactType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
