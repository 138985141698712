export class NumberUtils {
  static formatDecimalToPercent = (decimal: number): string => {
    return `${Math.round(decimal * 100)}%`;
  };

  static ordinal = (n: number): string => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  static isNumber(value: unknown, allowEmptyString = false): value is number {
    const isNumOrStr = typeof value === 'number' || typeof value === 'string';

    if (!isNumOrStr) {
      return false;
    }

    if (!allowEmptyString && value.toString().trim() === '') {
      return false;
    }

    return !isNaN(Number(value.toString()));
  }

  static format = (
    number: number | bigint | string,
    options?: Intl.NumberFormatOptions
  ): string => {
    return new Intl.NumberFormat('en-US', options).format(Number(number));
  };
}
