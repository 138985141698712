import { Table } from '@/shared/components';
import { useReportNumberOfEmployeeJobQuery } from '@/shared/graphql';
import { useMemo } from 'react';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilterType } from 'src/types/filters';
import { TableUtils } from '../../utils';

type ClientReportNumberOfEmployeeJob = {
  jobName: string;
};

export const NumberOfEmployeesJobTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { data, loading } = useReportNumberOfEmployeeJobQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });
  const yearMonths = TableUtils.generateColumnNames(
    Number(filters.current.year)
  );
  const jobs = useMemo(
    () => data?.reportNumberOfEmployeeJob.items.map((item) => item.job.name),
    [data?.reportNumberOfEmployeeJob.items]
  );

  const tableData: ClientReportNumberOfEmployeeJob[] = useMemo(
    () =>
      [...new Set(jobs)].map((job: string) => ({
        jobName: job,
        ...Object.fromEntries(
          (yearMonths || []).map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeJob.items.find(
              (item) => item.yearMonth === yearMonth && item.job.name === job
            )?.total ?? 0,
          ])
        ),
      })),
    [data?.reportNumberOfEmployeeJob.items, jobs, yearMonths]
  );

  const columns = useMemo(
    () => [
      {
        headerRender: () => <div />,
        accessor: 'job',
        tdClassName: '!bg-disabled border',
        thClassName: '!bg-disabled border',
        minWidth: '12rem',
        cellRender: (row: ClientReportNumberOfEmployeeJob) => (
          <span className='text-2xs'> {row.jobName}</span>
        ),
      },
      ...(yearMonths || []).map((yearMonth) => ({
        headerRender: () => (
          <div className='flex h-12 w-full items-center justify-center border-r px-3 text-4xs'>
            {yearMonth}
          </div>
        ),
        accessor: yearMonth,
        thClassName: loading ? undefined : 'p-0',
        tdClassName: loading ? undefined : 'h-10 p-0',
        cellRender: (row: ClientReportNumberOfEmployeeJob) => (
          <div className='flex h-full w-full items-center justify-end border-r px-3 text-2xs'>
            {row[yearMonth as keyof ClientReportNumberOfEmployeeJob].toString()}
          </div>
        ),
      })),
    ],
    [loading, yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeJob>
        layout={columns || []}
        data={tableData || []}
        loading={loading}
      />
    </div>
  );
};
