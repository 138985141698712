import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProbationReviewsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter: Types.InputMaybe<Types.ListProbationReviewFilterInput>;
}>;


export type ProbationReviewsQuery = { __typename?: 'Query', probationReviews: { __typename?: 'ListProbationReviews', totalCount: number, items: Array<{ __typename?: 'ProbationReview', employeeId: string, status: Types.ProbationReviewStatus, stages: Array<{ __typename?: 'ProbationReviewStage', deadline: any }>, employee: { __typename?: 'Employee', generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string, hiredDate: any, primaryManager: { __typename?: 'Employee', id: string, generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string } } | null }, currentContract: { __typename?: 'EmployeeContract', company: { __typename?: 'Company', name: string } } | null, currentPosition: { __typename?: 'EmployeeJobHistory', level: Types.JobHistoryLevel, location: { __typename?: 'Location', name: string }, businessUnit: { __typename?: 'BusinessUnit', name: string } } | null } }> } };


export const ProbationReviewsDocument = gql`
    query ProbationReviews($pageNumber: Int!, $pageSize: Int!, $filter: ListProbationReviewFilterInput) {
  probationReviews(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter) {
    items {
      employeeId
      stages {
        deadline
      }
      employee {
        generalInformation {
          preferredFullName
          hiredDate
          primaryManager {
            id
            generalInformation {
              preferredFullName
            }
          }
        }
        currentContract {
          company {
            name
          }
        }
        currentPosition {
          location {
            name
          }
          businessUnit {
            name
          }
          level
        }
      }
      status
    }
    totalCount
  }
}
    `;

/**
 * __useProbationReviewsQuery__
 *
 * To run a query within a React component, call `useProbationReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProbationReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProbationReviewsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProbationReviewsQuery(baseOptions: Apollo.QueryHookOptions<ProbationReviewsQuery, ProbationReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProbationReviewsQuery, ProbationReviewsQueryVariables>(ProbationReviewsDocument, options);
      }
export function useProbationReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProbationReviewsQuery, ProbationReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProbationReviewsQuery, ProbationReviewsQueryVariables>(ProbationReviewsDocument, options);
        }
export function useProbationReviewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProbationReviewsQuery, ProbationReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProbationReviewsQuery, ProbationReviewsQueryVariables>(ProbationReviewsDocument, options);
        }
export type ProbationReviewsQueryHookResult = ReturnType<typeof useProbationReviewsQuery>;
export type ProbationReviewsLazyQueryHookResult = ReturnType<typeof useProbationReviewsLazyQuery>;
export type ProbationReviewsSuspenseQueryHookResult = ReturnType<typeof useProbationReviewsSuspenseQuery>;
export type ProbationReviewsQueryResult = Apollo.QueryResult<ProbationReviewsQuery, ProbationReviewsQueryVariables>;