import { toast } from '@/shared/components';
import {
  useCreateEmployeeDependentMutation,
  useDeleteEmployeeDependentMutation,
  useUpdateEmployeeDependentMutation,
} from '@/shared/graphql';
import { EmployeeDependentType } from '@/shared/types';
import { ApiUtils } from '@/shared/utils';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

interface DependentPersistence {
  employeeId: string;
  dependents: EmployeeDependentType[];
  onDependentsChange: (data: EmployeeDependentType[]) => void;
  // this triggers Mutation to be triggered, otherwise we let user
  // handle manually the data that was modified (e.g. to be saved later)
  autoPersistInDatabase?: boolean;
}

export const useDependentPersistence = ({
  employeeId,
  dependents = [],
  onDependentsChange,
  autoPersistInDatabase = true,
}: DependentPersistence) => {
  const [createRecord] = useCreateEmployeeDependentMutation();
  const [updateRecord] = useUpdateEmployeeDependentMutation();
  const [deleteRecord] = useDeleteEmployeeDependentMutation();

  const onDependentCreate = useCallback(
    ({ id, ...formData }: EmployeeDependentType) => {
      if (autoPersistInDatabase) {
        createRecord({
          variables: {
            input: {
              ...formData,
              employeeId,
              relation: formData.relation,
            },
          },
          onCompleted: (data) => {
            toast.success();
            onDependentsChange([
              ...dependents,
              { ...formData, id: data.createEmployeeDependent.id },
            ]);
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onDependentsChange([...dependents, { ...formData, id: uuid() }]);
      }
    },
    [
      autoPersistInDatabase,
      dependents,
      createRecord,
      employeeId,
      onDependentsChange,
    ]
  );

  const onDependentUpdate = useCallback(
    (formData: EmployeeDependentType) => {
      if (autoPersistInDatabase) {
        updateRecord({
          variables: {
            input: {
              ...formData,
              relation: formData.relation,
            },
          },
          onCompleted: () => {
            toast.success();
            onDependentsChange([
              ...dependents.map((item) =>
                item.id === formData.id ? { ...item, ...formData } : item
              ),
            ]);
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onDependentsChange([
          ...dependents.map((item) =>
            item.id === formData.id ? { ...item, ...formData } : item
          ),
        ]);
      }
    },
    [autoPersistInDatabase, dependents, onDependentsChange, updateRecord]
  );

  const onDependentRemove = useCallback(
    (id: string) => {
      if (autoPersistInDatabase) {
        deleteRecord({
          variables: { id: id },
          onCompleted: () => {
            toast.success();
            onDependentsChange(dependents.filter((item) => item.id !== id));
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onDependentsChange(dependents.filter((item) => item.id !== id));
      }
    },
    [autoPersistInDatabase, dependents, deleteRecord, onDependentsChange]
  );

  return {
    onDependentCreate,
    onDependentUpdate,
    onDependentRemove,
  };
};
