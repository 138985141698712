import {
  BusinessUnitLazySelect,
  CompanyLazySelect,
  ContractText,
  ContractTypeSelect,
  DEFAULT_PAGE_SIZE,
  DepartmentLazySelect,
  EmployeeStatusSelect,
  EmployeeStatusText,
  InputSearch,
  JobHistoryLevelLabels,
  JobLevelSelect,
  LocationLazySelect,
} from '@/shared/components';
import { FilterProps } from '@/shared/hooks';
import { CustomFilterButton } from './CustomFilterButton';
import { EmployeeListFilterType } from './EmployeeList.filters';
import { MoreFilterButton } from './more-filters/MoreFilterButton';

export const EmployeeListFilter = ({
  filters,
}: {
  filters: FilterProps<EmployeeListFilterType>;
}) => {
  const { changeFilter, removeFilter, current } = filters;

  return (
    <>
      <div className='grid grid-cols-6 gap-4 p-4'>
        <div className='col-span-3'>
          <InputSearch
            className='w-full'
            onClear={() => removeFilter('keyword')}
            onSearch={(text: string) => changeFilter('keyword', text)}
            placeholder={'Keyword search'}
            value={current.keyword}
          />
        </div>
        <div>
          <EmployeeStatusSelect
            isMulti={true}
            onChange={(newValue) => {
              changeFilter(
                'statuses',
                newValue.map(({ value }) => value)
              );
            }}
            value={current.statuses?.map((item) => ({
              label: String(EmployeeStatusText[item]),
              value: item,
            }))}
          />
        </div>
        <div>
          <ContractTypeSelect
            isMulti={true}
            onChange={(newValue) =>
              changeFilter(
                'contractTypes',
                newValue.map(({ value }) => value)
              )
            }
            value={current.contractTypes?.map((item) => ({
              label: String(ContractText[item]),
              value: item,
            }))}
          />
        </div>
        <div>
          <JobLevelSelect
            isMulti={true}
            onChange={(newValue) =>
              changeFilter(
                'levels',
                newValue.map(({ value }) => value)
              )
            }
            value={current.levels?.map((item) => ({
              label: JobHistoryLevelLabels[item],
              value: item,
            }))}
          />
        </div>
        <div>
          <CompanyLazySelect
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            value={current.companyIds}
            valueFormatter={(item) => item.id}
            defaultQueryOptions={{
              variables: {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                keyword: null,
                filterOnlyAccessible: false,
                enabled: null,
              },
            }}
            placeholder={'Companies'}
            onChange={(newValue) => {
              changeFilter(
                'companyIds',
                newValue.map(({ value }) => value)
              );
            }}
          />
        </div>
        <div>
          <LocationLazySelect
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            value={current.locationIds}
            defaultQueryOptions={{
              variables: {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                enabled: null,
                keyword: null,
              },
            }}
            placeholder={'Locations'}
            onChange={(option) => {
              changeFilter(
                'locationIds',
                option.map(({ value }) => value)
              );
            }}
          />
        </div>
        <div>
          <BusinessUnitLazySelect
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            value={current.businessUnitIds}
            onChange={(newValue) => {
              changeFilter(
                'businessUnitIds',
                newValue.map(({ value }) => value)
              );
            }}
            defaultQueryOptions={{
              variables: {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                keyword: null,
                enabled: null,
              },
            }}
            placeholder={'Business Unit'}
          />
        </div>
        <div>
          <DepartmentLazySelect
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            value={current.departmentIds}
            valueFormatter={(item) => item.id}
            onChange={(newValue) => {
              changeFilter(
                'departmentIds',
                newValue.map(({ value }) => value)
              );
            }}
            defaultQueryOptions={{
              variables: {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                enabled: null,
              },
            }}
            placeholder={'Department'}
          />
        </div>
        <div>
          <MoreFilterButton filters={filters} />
        </div>
        <CustomFilterButton filters={filters} />
      </div>
    </>
  );
};
