import { toast } from '@/shared/components';
import {
  useCreateEmployeeEmergencyContactMutation,
  useDeleteEmployeeEmergencyContactMutation,
  useUpdateEmployeeEmergencyContactMutation,
} from '@/shared/graphql';
import { EmergencyContactType } from '@/shared/types';
import { ApiUtils } from '@/shared/utils';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

interface EmergencyContactPersistence {
  employeeId: string;
  emergencyContacts: EmergencyContactType[];
  onEmergencyContactsChange: (data: EmergencyContactType[]) => void;
  // this triggers Mutation to be triggered, otherwise we let user
  // handle manually the data that was modified (e.g. to be saved later)
  autoPersistInDatabase?: boolean;
}

export const useEmergencyContactPersistence = ({
  employeeId,
  emergencyContacts = [],
  onEmergencyContactsChange,
  autoPersistInDatabase = true,
}: EmergencyContactPersistence) => {
  const [createRecord] = useCreateEmployeeEmergencyContactMutation();
  const [updateRecord] = useUpdateEmployeeEmergencyContactMutation();
  const [deleteRecord] = useDeleteEmployeeEmergencyContactMutation();

  const onEmergencyContactCreate = useCallback(
    (formData: EmergencyContactType) => {
      if (autoPersistInDatabase) {
        createRecord({
          variables: {
            input: {
              employeeId: employeeId,
              name: formData.name,
              phoneNumber: {
                number: formData.phoneNumber,
                countryId: formData.phoneNumberCountryCode,
              },
              relation: formData.relation,
              remarks: formData.remarks,
            },
          },
          onCompleted: (data) => {
            toast.success();
            onEmergencyContactsChange([
              ...emergencyContacts,
              { ...formData, id: data.createEmployeeEmergencyContact.id },
            ]);
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onEmergencyContactsChange([
          ...emergencyContacts,
          { ...formData, id: uuid() },
        ]);
      }
    },
    [
      autoPersistInDatabase,
      emergencyContacts,
      createRecord,
      employeeId,
      onEmergencyContactsChange,
    ]
  );

  const onEmergencyContactUpdate = useCallback(
    (formData: EmergencyContactType) => {
      if (autoPersistInDatabase) {
        updateRecord({
          variables: {
            input: {
              id: formData.id,
              name: formData.name,
              phoneNumber: {
                number: formData.phoneNumber,
                countryId: formData.phoneNumberCountryCode,
              },
              relation: formData.relation,
              remarks: formData.remarks,
            },
          },
          onCompleted: () => {
            toast.success();
            onEmergencyContactsChange([
              ...emergencyContacts.map((item) =>
                item.id === formData.id ? { ...item, ...formData } : item
              ),
            ]);
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onEmergencyContactsChange([
          ...emergencyContacts.map((item) =>
            item.id === formData.id ? { ...item, ...formData } : item
          ),
        ]);
      }
    },
    [
      autoPersistInDatabase,
      emergencyContacts,
      onEmergencyContactsChange,
      updateRecord,
    ]
  );

  const onEmergencyContactRemove = useCallback(
    (id: string) => {
      if (autoPersistInDatabase) {
        deleteRecord({
          variables: { id: id },
          onCompleted: () => {
            toast.success();
            onEmergencyContactsChange(
              emergencyContacts.filter((item) => item.id !== id)
            );
          },
          onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
        });
      } else {
        onEmergencyContactsChange(
          emergencyContacts.filter((item) => item.id !== id)
        );
      }
    },
    [
      autoPersistInDatabase,
      emergencyContacts,
      deleteRecord,
      onEmergencyContactsChange,
    ]
  );

  return {
    onEmergencyContactCreate,
    onEmergencyContactUpdate,
    onEmergencyContactRemove,
  };
};
