import { EmployeeDetailType } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { Link } from 'react-router-dom';
import { ReadonlyDetail } from '../../../../../components/employees/details';

export const GeneralInformationDetails = ({
  employee,
}: {
  employee: EmployeeDetailType;
}) => {
  const { generalInformation: employeeGeneralInformation, probationReview } =
    employee;

  return (
    <div>
      <div className='form-fieldset gap-6'>
        <ReadonlyDetail
          label='Preferred Full Name in English'
          value={employeeGeneralInformation.preferredFullName}
        />

        <ReadonlyDetail
          label='Legal Full name in English'
          value={employeeGeneralInformation.legalFullName}
        />

        <ReadonlyDetail
          label='Name in Local Language'
          value={employeeGeneralInformation.nameInLocalLanguage}
        />

        <ReadonlyDetail
          label='ﾌﾘｶﾞﾅ (Kana of Japanese)'
          value={employeeGeneralInformation.japanesePhoneticName}
        />

        <ReadonlyDetail
          label='Nationality'
          value={employeeGeneralInformation.nationality}
        />

        <ReadonlyDetail
          label='Primary Manager'
          value={
            <Link
              className='text-blue-6'
              to={`/employees/edit/${employeeGeneralInformation.primaryManagerId}`}
            >
              {employeeGeneralInformation.primaryManager}
            </Link>
          }
        />

        <ReadonlyDetail
          label='Dotted Line Manager'
          value={
            employeeGeneralInformation.dottedLineManagerId ? (
              <Link
                className='text-blue-6'
                to={`/employees/edit/${employeeGeneralInformation.dottedLineManagerId}`}
              >
                {employeeGeneralInformation.dottedLineManager}
              </Link>
            ) : null
          }
        />

        <ReadonlyDetail
          label='Hired Date'
          value={DateUtils.formatDate(
            employeeGeneralInformation.hiredDate,
            'yyyy-MM-dd'
          )}
        />

        <div className='col-span-2'>
          <ReadonlyDetail
            label='Company Email'
            value={employeeGeneralInformation.emailAddress}
          />
        </div>

        <div className='col-span-2'>
          <ReadonlyDetail
            label='Scheduled Probation End Date'
            value={
              probationReview.probationEndDate
                ? DateUtils.formatDate(
                    probationReview.probationEndDate,
                    'MMM dd, yyyy'
                  )
                : null
            }
          />
        </div>

        <ReadonlyDetail
          label='Hiring Source'
          value={
            employeeGeneralInformation.hiringSource
              ? StringUtils.capitalizeAll(
                  employeeGeneralInformation.hiringSource,
                  '_'
                )
              : null
          }
        />

        <ReadonlyDetail
          label='Greenhouse Link'
          value={
            employeeGeneralInformation.greenhouseLink ? (
              <Link
                className='text-blue-6'
                target='_blank'
                to={employeeGeneralInformation.greenhouseLink}
              >
                {employeeGeneralInformation.greenhouseLink}
              </Link>
            ) : null
          }
        />
      </div>
    </div>
  );
};
