import { JobHistoryLevel, ProbationReviewStatus } from '@/shared/graphql';
import { z } from 'zod';

export const ProbationReview = z.object({
  employeeId: z.string().default(''),
  employeeName: z.string().default(''),
  hiredDate: z.string().default(''),
  companyName: z.string().default(''),
  officeLocation: z.string().default(''),
  businessUnit: z.string().default(''),
  level: z.nativeEnum(JobHistoryLevel).default(JobHistoryLevel.L0),
  primaryManager: z.string().default(''),
  primaryManagerId: z.string().default(''),
  reviewDates: z.array(z.string()).default([]),
  status: z
    .nativeEnum(ProbationReviewStatus)
    .default(ProbationReviewStatus.Draft),
});

export type ProbationReviewType = z.infer<typeof ProbationReview>;
