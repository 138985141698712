import { z } from 'zod';

export const EmployeeDirectReport = z.object({
  name: z.string().default(''),
  company: z.string().default(''),
  location: z.string().default(''),
  division: z.string().default(''),
  businessUnit: z.string().default(''),
  job: z.string().default(''),
  level: z.string().default(''),
  jobTitle: z.string().default(''),
  employeeId: z.string().default(''),
});
export type EmployeeDirectReportType = z.infer<typeof EmployeeDirectReport>;
