import { Button, ModalFooter, TextInput } from '@any-ui-react/core';

import { DatePicker } from '@/shared/components';
import {
  EmployeeVisa,
  EmployeeVisaType,
  VisaFormData,
  VisaFormDataType,
} from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { visaFormValidationSuite } from './visa-form.validator';

export interface VisaFormProps {
  onSuccess: (data: EmployeeVisaType) => void;
  onCancel: () => void;
  visa?: EmployeeVisaType;
}

export const VisaForm = ({ visa, onSuccess, onCancel }: VisaFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    clearErrors,
  } = useForm<VisaFormDataType>({
    defaultValues: VisaFormData.parse({ ...visa }),
    resolver: vestResolver(visaFormValidationSuite),
  });

  const [
    visaIssuedDate,
    visaExpiryDate,
    workPermitIssuedDate,
    workPermitExpiryDate,
  ] = watch([
    'visaIssuedDate',
    'visaExpiryDate',
    'workPermitIssuedDate',
    'workPermitExpiryDate',
  ]);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        onSuccess(EmployeeVisa.parse({ ...formValues, id: visa?.id }));
      }),
    [handleSubmit, onSuccess, visa?.id]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('visaType')}
          label={'Visa Type'}
          error={errors.visaType?.message}
          placeholder={'Visa Type'}
          className='col-span-2'
        />
        <TextInput
          {...register('visaNumber')}
          label={'Visa Number'}
          error={errors.visaNumber?.message}
          placeholder={'Visa Number'}
          className='col-span-2'
        />
        <div className='z-10 col-span-2'>
          <DatePicker
            error={errors.visaIssuedDate?.message}
            label='Visa issued date'
            placeholder='Visa issued date'
            value={visaIssuedDate || undefined}
            onChange={(value) => {
              setValue(
                'visaIssuedDate',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('visaIssuedDate');
            }}
          />
        </div>
        <div className='col-span-2'>
          <DatePicker
            label='Visa expiry date'
            error={errors.visaExpiryDate?.message}
            placeholder='Visa expiry date'
            value={visaExpiryDate || undefined}
            onChange={(value) => {
              setValue(
                'visaExpiryDate',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('visaExpiryDate');
            }}
          />
        </div>
        <TextInput
          {...register('workPermitNumber')}
          label={'Work permit number'}
          error={errors.workPermitNumber?.message}
          placeholder={'Work permit number'}
          className='col-span-2'
        />
        <div className='col-span-2'>
          <DatePicker
            label='Work permit issued date'
            error={errors.workPermitIssuedDate?.message}
            placeholder='Work permit issued date'
            value={workPermitIssuedDate || undefined}
            onChange={(value) => {
              setValue(
                'workPermitIssuedDate',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('workPermitIssuedDate');
            }}
          />
        </div>
        <div className='col-span-2'>
          <DatePicker
            label='Work permit expiry date'
            error={errors.workPermitExpiryDate?.message}
            placeholder='Work permit expiry date'
            value={workPermitExpiryDate || undefined}
            onChange={(value) => {
              setValue(
                'workPermitExpiryDate',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('workPermitExpiryDate');
            }}
          />
        </div>
        {errors.allFields && (
          <span className='text-red-6 text-sm'>{errors.allFields.message}</span>
        )}
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
