import { toast } from '@/shared/components';
import {
  EmployeeGender,
  useUpdateEmployeePersonalInformationMutation,
} from '@/shared/graphql';
import { EmployeePersonalInformationFormDataType } from '@/shared/types';
import { ApiUtils } from '@/shared/utils';

interface HookProps {
  onCompleted: VoidFunction;
}

export const useUpdateEmployeePersonalInformation = ({
  onCompleted,
}: HookProps) => {
  const [updateRecord] = useUpdateEmployeePersonalInformationMutation();

  const handleUpdateEmployeePersonalInformation = (
    formData: EmployeePersonalInformationFormDataType,
    employeeId: string
  ) => {
    updateRecord({
      variables: {
        input: {
          bank: {
            accountHolderName: formData.accountHolderName,
            accountNumber: formData.accountNumber,
            branchName: formData.branchName,
            countryId: formData.bankCountryCode,
            name: formData.bankName,
          },
          consultingFeeBank: {
            accountHolderName: formData.consultingFeeAccountHolderName,
            accountNumber: formData.consultingFeeAccountNumber,
            branchName: formData.consultingFeeBranchName,
            countryId: formData.consultingFeeBankCountryCode,
            currencyId: formData.consultingFeeBankCurrencyId,
            name: formData.consultingFeeBankName,
          },
          currentAddress: formData.currentAddress,
          dateOfBirth: formData.dateOfBirth,
          employeeId: employeeId,
          gender: formData.gender as EmployeeGender,
          idExpiryDate: formData.idExpiryDate,
          idIssuedDate: formData.idIssuedDate,
          idIssuedPlace: formData.idIssuedPlace,
          idNumber: formData.idNumber,
          idType: formData.idType,
          maritalStatus: formData.maritalStatus,
          nhiDependents: formData.nhiDependents,
          passportExpiryDate: formData.passportExpiryDate,
          passportIssuedDate: formData.passportIssuedDate,
          passportIssuedPlace: formData.passportIssuedPlace,
          passportName: formData.passportName,
          passportNumber: formData.passportName,
          passportPlaceOfBirth: formData.placeOfBirth,
          pensionVoluntaryContributionRate:
            formData.pensionVoluntaryContributionRate,
          permanentAddress: formData.permanentAddress,
          personalEmail: formData.personalEmail,
          personalPhoneNumber:
            formData.personalPhoneNumberCountryCode &&
            formData.personalPhoneNumber
              ? {
                  countryId: formData.personalPhoneNumberCountryCode,
                  number: formData.personalPhoneNumber,
                }
              : null,
          socialInsuranceNotes: formData.socialInsuranceNotes,
          socialInsuranceNumber: formData.socialInsuranceNumber,
          taxCode: formData.taxCode,
          taxWithholdingRate: formData.taxWithholdingRate,
          residentialCountryId: formData.residentialCountryId,
          remarks: formData.remarks,
        },
      },
      onCompleted: (data) => {
        if (data.updateEmployeePersonalInformation) {
          toast.success();
          onCompleted();
        }
      },
      onError: (error) => toast.error(ApiUtils.getErrorMessage(error)),
    });
  };

  return {
    handleUpdateEmployeePersonalInformation,
  };
};
