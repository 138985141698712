import {
  EMPTY_DEFAULT,
  NoResult,
  Table,
  TableLayout,
  TableProps,
} from '@/shared/components';
import { TableSelection, UserEmployeeType } from '@/shared/types';
import { useMemo } from 'react';

interface AddEmployeeTableProps {
  loading: boolean;
  items: UserEmployeeType[];
  selection: TableSelection<UserEmployeeType>;
  setSelection: (selection: TableSelection<UserEmployeeType>) => void;
  total?: number;
  showSelected?: boolean;
}

export const AddEmployeeTable = ({
  items,
  loading,
  selection,
  setSelection,
  showSelected,
  total = 0,
}: AddEmployeeTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'legalName',
          cellRender: (userEmployee: UserEmployeeType) => {
            return <span>{userEmployee.legalName}</span>;
          },
        },

        {
          headerRender: () => 'Company',
          accessor: 'company',
          cellRender: (userEmployee: UserEmployeeType) => {
            return <span>{userEmployee.company || EMPTY_DEFAULT}</span>;
          },
        },
        {
          headerRender: () => 'Business Unit',
          accessor: 'businessUnit',
          cellRender: (userEmployee: UserEmployeeType) => {
            return <span>{userEmployee.businessUnit || EMPTY_DEFAULT}</span>;
          },
        },
        {
          headerRender: () => 'Department',
          accessor: 'department',
          cellRender: (userEmployee: UserEmployeeType) => {
            return <span>{userEmployee.department || EMPTY_DEFAULT}</span>;
          },
        },
      ] as TableLayout<UserEmployeeType>[],
    []
  );

  const tableCommonProps: TableProps<UserEmployeeType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title='No Data Found'
        description='Try changing the filters or search terms'
      />
    ),
    ...(showSelected
      ? {
          selectable: {
            byField: 'id',
            total: total,
          },
          onSelectionChange: setSelection,
          selection: selection,
        }
      : []),
  };

  return (
    <>
      <Table<UserEmployeeType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
