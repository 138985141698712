import { z } from 'zod';

export const VisaFormData = z.object({
  visaType: z.string().nullable().default(null),
  visaNumber: z.string().nullable().default(null),
  visaIssuedDate: z.string().nullable().default(null),
  visaExpiryDate: z.string().nullable().default(null),
  workPermitNumber: z.string().nullable().default(null),
  workPermitIssuedDate: z.string().nullable().default(null),
  workPermitExpiryDate: z.string().nullable().default(null),
  allFields: z.boolean().default(false),
});
export type VisaFormDataType = z.infer<typeof VisaFormData>;
